import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import {
  inputDateFormat,
  inputDateTimeFormat,
  outputDateFormat,
  outputDateTimeFormat,
} from "../Constants/global";
import formatDate from "../DateTimeFormat/DateTimeFormatter";
const containerStyle = {
  width: "100%",
  height: "fit-content",
  position: "absolute",
  bottom: 0,
  backgroundColor: "#808080",
};

// var mapOptions = {
//   zoom: 8,
//   mapTypeId: "satellite",
// };

// const mapStyle = [
//   {
//     featureType: "landscape.man_made",
//     elementType: "satellite",
//     mapOptions,
//     mapTypeId: "satellite",
//     stylers: [
//       {
//         color: "#dceafa",
//       },
//     ],
//   },
// ];

export default class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.handleDirection = this.handleDirection.bind(this);

    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      data: this.props.MData,
      direction: this.props.direction,
    };
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.MData !== this.state.data) {
      this.setState({ data: nextProps.MData });
    }
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
    setTimeout(this.applyEvent, 500);
  };

  applyEvent = () => {
    var element = document.getElementsByClassName("direction-link");
    if (element.length !== 0) {
      element[0].addEventListener("click", this.handleDirection, false);
    }
  };

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  // _mapLoaded(mapProps, map) {
  //   map.setOptions({
  //     styles: mapStyle,
  //   });
  // }

  handleDirection = () => {
    this.props.setSelected({
      ...this.props.selected,
      ["Icon"]: this.state.selectedPlace.icon,
      ["position"]: this.state.selectedPlace.position,
      ["info"]: this.state.selectedPlace.id,
    });
    localStorage.setItem(
      "selectedPlace",
      JSON.stringify({
        ["Icon"]: this.state.selectedPlace.icon,
        ["position"]: this.state.selectedPlace.position,
        ["info"]: this.state.selectedPlace.id,
      })
    );
    this.props.setGoDirection(true);
  };

  render() {
    // console.log(this.props.MData, "maaaaaappppp");

    // function formatDate(date, time) {
    //   if (date !== null) {
    //     if (time) {
    //       var dd = date.slice(6, 8);
    //       var mm = date.slice(4, 6); //January is 0!

    //       var yyyy = date.slice(0, 4);
    //       var hr = date.slice(9, 11);
    //       var minu = date.slice(11, 13);
    //       var dte = dd + "/" + mm + "/" + yyyy + " " + hr + ":" + minu + "Hrs";
    //       return dte;
    //     } else {
    //       var dd = date.slice(6, 8);
    //       var mm = date.slice(4, 6); //January is 0!

    //       var yyyy = date.slice(0, 4);
    //       var dte = dd + "/" + mm + "/" + yyyy;
    //       return dte;
    //     }
    //   }
    // }
    return (
      <>
        <Map
          onClick={this.onMapClicked}
          google={this.props.google}
          zoom={6}
          // onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
          containerStyle={containerStyle}
          style={{
            width: this.props.width,
            height: this.props.height,
            position: "relative",
            margin: 0,
            padding: 0,
          }}
          initialCenter={{
            lat: localStorage.getItem("Current_Location")
              ? JSON.parse(localStorage.getItem("Current_Location")).lat
              : -33.865143,
            lng: localStorage.getItem("Current_Location")
              ? JSON.parse(localStorage.getItem("Current_Location")).lng
              : 151.2099,
          }}
          options={{ mapTypeId: "hybrid" }}
        >
          <Marker
            name="Current location"
            onClick={this.onMarkerClick}
            icon="/assets/img/UserIcon.png"
          />
          {this.props.MData != null ? (
            this.props.direction == true ? (
              <Marker
                position={{
                  lat: this.state.data.position.lat,
                  lng: this.state.data.position.lng,
                }}
                icon={this.state.data.Icon}
              />
            ) : (
              this.state.data.map((item, i) => (
                <Marker
                  key={i}
                  id={`${item.Model} S/N: ${item.SerialNumber}`}
                  name={`Model: ${item.Model} & S/N: ${item.SerialNumber}<br> 
                Name: ${item.CustomerName}<br>
                SMR: ${item.SMR} & Date: ${formatDate(
                    item.LastCommDate,
                    inputDateTimeFormat,
                    outputDateFormat
                  )}<br>
                GPS Date: ${formatDate(
                  item.GPSDate,
                  inputDateTimeFormat,
                  outputDateTimeFormat
                )}<br>`}
                  onClick={this.onMarkerClick}
                  position={{ lat: item.Lat, lng: item.Long }}
                  icon={item.Icon}
                />
              ))
            )
          ) : (
            ""
          )}
          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
          >
            <>
              <div
                style={{
                  width: "190px",
                  height: "auto",
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectedPlace.name,
                  }}
                ></div>
                {this.state.selectedPlace.name === "Current location" ? (
                  ""
                ) : (
                  <div
                    className="direction-link"
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    View Driving Direction
                  </div>
                )}
              </div>
            </>
          </InfoWindow>
        </Map>
      </>
    );
  }
}

MapContainer = GoogleApiWrapper({
  apiKey: "AIzaSyCEekrXJHwgPpVPFsBrZLI0wOOU8JK3pjY",
})(MapContainer);
