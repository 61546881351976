import React, { useEffect } from "react";
import moment from "moment";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";

export default function ChartLine(props) {
  const [array, setArray] = useState([]);
  const [chartData, setChartData] = useState([]);

  const formatXAxis = (tickItem) => {
    return moment(tickItem).format("MMM D");
  };

  useEffect(() => {
    setArray([]);
    props.chartData.map((item) => {
      if (item.value !== 0) {
        array.push(item);
      } else {
        array.push({ date: item.date, value: null });
      }
    });
    setChartData(array);
  }, [props]);

  const drawChart = () => {
    return (
      <div style={{ width: "100%", height: props.mapHeight }}>
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 35,
              left: -7,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatXAxis} />
            <YAxis />
            <Tooltip
              labelFormatter={(t) => moment(t).format("DD/MM/YYYY")}
              formatter={(t) => t.toFixed(1)}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#140A9A"
              strokeWidth={3}
              activeDot={{ r: 8 }}
              fill="#140A9A"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };
  useEffect(() => {
    drawChart();
  }, [props]);
  return <>{drawChart()}</>;
}
