import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function RedirectToStaffLogin() {
  const classes = useStyles();

  useEffect(() => {
    window.location.href = "https://dfm.komtrax.komatsu/d/fm/";
  }, []);
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default RedirectToStaffLogin;
