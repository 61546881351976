import moment from "moment";
function formatDate(date, inputFormat, outputFormat) {
  if (date) {
    const time = moment.utc(date, inputFormat).local();
    return time.format(outputFormat);
  }
  return "";
}

export default formatDate;
