import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { IconContext } from "react-icons";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import MapContainer from "../../Components/Map";
import { DateRangePicker, DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import useWindowDimensions from "../../GetDimenssions/GetWindowSize";

const { allowedRange } = DateRangePicker;

let rowId = null;
let serialNum = null;

const NoUsage = () => {
  let upperContentHeight;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { height, width } = useWindowDimensions();
  const ref = useRef(null);

  const [MachineData, setMachineData] = useState({});
  const [copyData, setCopyData] = useState([]);
  const [MData, setMData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [goDirection, setGoDirection] = useState(false);
  const [selected, setSelected] = useState({});
  const [mapHeight, setMapHeight] = useState(0);

  const handleSingleClick = (id, SrNum) => {
    if (rowId !== id) {
      rowId = id;
      serialNum = SrNum;
      DrawMap("Detailed", "no-usage", id, SrNum, "");
    }
    const alreadySelected = document.querySelector(".selected-row");
    if (alreadySelected) {
      alreadySelected.classList.remove("selected-row");
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    } else {
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    }
  };

  var LatestDate = state
    ? state.LatestDate
    : localStorage.getItem("machinesData").LatestDate;
  var EndDate = Endate().toString();

  const [eDate, setEDate] = useState(new Date(formatDate1(EndDate, true)));
  const [lDate, setLDate] = useState(new Date(formatDate1(LatestDate, true)));

  function Endate() {
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    var days_in_month = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    LatestDate = MachineData.LatestDate;

    //Split lates date into year , month and day
    var Latest_day = LatestDate.substring(6, 8);
    var Latest_month = parseInt(LatestDate.substring(4, 6)) - 1;
    var Latest_year = LatestDate.substring(0, 4);

    //declare new date Object
    var Dateobj = new Date(Latest_year, Latest_month, Latest_day);
    Dateobj.setDate(Dateobj.getDate() - 30);

    //get 30 days old(previous_values) from date object
    var Previous_Year = Dateobj.getFullYear();
    var Previous_Month = (Dateobj.getMonth() + 1).toString();
    var Previous_Day = Dateobj.getDate().toString();

    if (Previous_Month.length == 1) {
      Previous_Month = "0" + Previous_Month;
    }
    if (Previous_Day.length == 1) {
      Previous_Day = "0" + Previous_Day;
    }
    var Previous_Date = parseInt(Previous_Year + Previous_Month + Previous_Day);

    return Previous_Date;
  }

  function DrawMap(Screen, Row, MachineID, SerialNumber, Machine_data) {
    var Data = [];
    var markersArray = [];

    setLoading(true);
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    if (localStorage.getItem("Desired_Location") !== null) {
      //Delete previous deatination location from local storage
      localStorage.removeItem("Desired_Location");
    }

    if (Screen == "Detailed") {
      var Machines = MachineData.Machines;
      Machines.forEach((item) => {
        if (item.SerialNumber === SerialNumber) {
          if (Row == "no-usage") {
            Data.length = 0;
            if (item.GPSDate !== null) {
              Data.push({
                Model: item.Model + "-" + item.Type,
                SerialNumber: item.SerialNumber,
                Lat: item.Location.Lat,
                Long: item.Location.Long,
                CustomerName: item.CustomerDesc,
                SMR: item.SMR,
                LastCommDate: item.LatestComDate,
                GPSDate: item.GPSDate,
                Icon: "/assets/img/pin_purple.png",
              });
            }
          }
        }
      });
    }
    markersArray = Data;
    setMData(markersArray);
    setLoading(false);
  }

  function NoUsage(NewDate, OldDate) {
    var Data = [];

    var MachineData = JSON.parse(localStorage.getItem("machinesData")); //Get all machine data
    var ShowUN = JSON.parse(localStorage.getItem("ShowUN"));
    var i,
      j = 2,
      k = 1;
    LatestDate = MachineData.LatestDate + "000000";
    NewDate = NewDate;
    for (i = 0; i < MachineData.Machines.length; i++) {
      if (
        NewDate > MachineData.Machines[i].LastWorkDate &&
        NewDate <= MachineData.Machines[i].LatestComDate
      ) {
        var date = formatDate(MachineData.Machines[i].LastWorkDate, false);
        Data.push({
          Model:
            MachineData.Machines[i].Model + "-" + MachineData.Machines[i].Type,
          Date: date,
          Row: "Row" + j,
          SerialNumber: MachineData.Machines[i].SerialNumber,
          UnitNumber: MachineData.Machines[i].UnitNumber,
          MachineID: MachineData.Machines[i].MachineId,
        });
        setCopyData(Data);
      }
    }
  }

  function formatDate(date, time) {
    if (date !== null && date !== "") {
      if (time) {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var hr = date.slice(9, 11);
        var minu = date.slice(11, 13);
        var dte = dd + "/" + mm + "/" + yyyy + " " + hr + ":" + minu + "Hrs";
        return dte;
      } else {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var dte = dd + "/" + mm + "/" + yyyy;
        return dte;
      }
    } else {
      return null;
    }
  }

  function formatDate1(date, range) {
    var dd = date.slice(6, 8);
    var mm = date.slice(4, 6); //January is 0!
    var yyyy = date.slice(0, 4);
    if (range) {
      var dateRange = yyyy + "-" + mm + "-" + dd;
      return dateRange;
    } else {
      var dte = yyyy + "/" + mm + "/" + dd;
      return dte;
    }
  }
  function formatRange(date) {
    var mm = date.slice(0, 2);
    var dd = date.slice(3, 5);
    var yyyy = date.slice(6, 10);

    var dte = yyyy + mm + dd;
    return dte;
  }

  useEffect(() => {
    if (localStorage.getItem("machinesData")) {
      setMachineData(JSON.parse(localStorage.getItem("machinesData")));
    } else {
      setMachineData(state);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(MachineData).length !== 0) {
      NoUsage(
        formatRange(moment(lDate).format("MM/DD/YYYY")),
        formatRange(moment(eDate).format("MM/DD/YYYY"))
      );
    }
  }, [MachineData]);

  useEffect(() => {
    if (copyData.length !== 0) {
      const row = document.getElementById(copyData[0].MachineID);
      row.classList.add("selected-row");
      rowId = copyData[0].MachineID;
      DrawMap("Detailed", "no-usage", "", copyData[0].SerialNumber, "");
    }
    // DrawMap("Home", "no-usage", "", "", "", setLoading, setMData);
  }, [copyData]);

  // useEffect(() => {
  //   if (copyData.length !== 0) {
  //     DrawMap("Detailed", "no-usage", "", serialNum, "");
  //   }
  // }, [lDate, eDate]);

  useEffect(() => {
    if (goDirection) {
      navigate("/driving-direction");
    }
  }, [goDirection]);

  useLayoutEffect(() => {
    upperContentHeight = ref.current.clientHeight;
    setMapHeight(height - upperContentHeight);
  }, [height, copyData, width]);

  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{
                position: "relative",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div ref={ref}>
                <div className="row filter-header m-0 d-flex justify-content-between align-items-center">
                  <div className="col">
                    <button className="header-btn" onClick={() => navigate(-1)}>
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <RiArrowGoBackLine /> {width <= 335 ? "" : "Back"}
                      </IconContext.Provider>
                    </button>
                  </div>
                  <div
                    className="col text-center"
                    style={{ paddingTop: "1px" }}
                  >
                    <img src="/assets/img/logo.png" alt="komtrax logo" />
                  </div>
                  <div className="col d-flex justify-content-end">
                    <button
                      className="header-btn"
                      onClick={() => navigate("/prefrence")}
                    >
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <FiSettings /> {width <= 335 ? "" : " Prefs"}
                      </IconContext.Provider>
                    </button>
                    {/* &nbsp;
                  <button
                    className="header-btn"
                    onClick={() => navigate("/filter")}
                  >
                    <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <BsSearch /> Filter
                    </IconContext.Provider>
                  </button> */}
                  </div>
                </div>
                <div className="row m-0">
                  <div
                    className="col m-0 p-0"
                    style={{ maxHeight: "27vh", overflowY: "auto" }}
                  >
                    <Table>
                      <thead className="fltr-table-head">
                        <tr className="text-center">
                          <th>Model</th>
                          <th>
                            {localStorage.getItem("ShowUN") === "true"
                              ? "Unit"
                              : "Serial"}
                          </th>
                          <th>Last worked</th>
                        </tr>
                      </thead>
                      {copyData.length !== 0 ? (
                        <tbody className="fltr-table-body">
                          {copyData.map((item, i) => (
                            <tr
                              key={i}
                              className="text-center"
                              onClick={() =>
                                handleSingleClick(
                                  item.MachineID,
                                  item.SerialNumber
                                )
                              }
                              id={item.MachineID}
                            >
                              <td>{item.Model}</td>
                              <td>
                                {localStorage.getItem("ShowUN") === "true"
                                  ? item.UnitNumber
                                  : item.SerialNumber}
                              </td>
                              <td>{item.Date != null ? item.Date : "--"}</td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody></tbody>
                      )}
                    </Table>
                    {copyData.length === 0 && (
                      <div className="text-center text-muted">N/A</div>
                    )}
                  </div>
                </div>
                <div className="heading-bar text-center">Map</div>
                <div className="row px-4 mb-2">
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      From
                    </label>
                    <DatePicker
                      oneTap
                      value={eDate}
                      onChange={setEDate}
                      disabledDate={allowedRange(
                        formatDate1(EndDate),
                        formatDate1(LatestDate)
                      )}
                      placeholder="From date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      To
                    </label>
                    <DatePicker
                      oneTap
                      value={lDate}
                      onChange={setLDate}
                      disabledDate={allowedRange(
                        formatDate1(EndDate),
                        formatDate1(LatestDate)
                      )}
                      placeholder="To date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                  <div style={{ height: "10px" }}></div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    <MapContainer
                      setGoDirection={setGoDirection}
                      setSelected={setSelected}
                      selected={selected}
                      MData={MData.length === 0 ? null : MData}
                      height={mapHeight}
                      width={"100%"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoUsage;
