import React from "react";
import moment from "moment";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useEffect } from "react";

export default function ChartBar(props) {
  const formatXAxis = (tickItem) => {
    return moment(tickItem).format("MMM D");
  };
  const drawChart = () => {
    return (
      <div style={{ width: "100%", height: props.mapHeight }}>
        <ResponsiveContainer>
          <BarChart
            data={props.chartData}
            margin={{
              top: 5,
              right: 32,
              left: -20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatXAxis} />
            <YAxis />
            <Tooltip
              labelFormatter={(t) => moment(t).format("DD/MM/YYYY")}
              formatter={(t) => t.toFixed(1)}
            />
            <Bar dataKey="value" fill="#140A9A" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  useEffect(() => {
    drawChart();
  }, [props]);
  return <>{drawChart()}</>;
}
