import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import DirectionMap from "../../Components/DirectionMap";
import useWindowDimensions from "../../GetDimenssions/GetWindowSize";

const DrivingDirection = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("selectedPlace"))
  );
  const { height, width } = useWindowDimensions();

  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <div className="row filter-header m-0 d-flex justify-content-between align-items-center">
                <div className="col">
                  <button className="header-btn" onClick={() => navigate(-1)}>
                    <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <RiArrowGoBackLine /> {width <= 335 ? "" : " Back"}
                    </IconContext.Provider>
                  </button>
                </div>
                <div className="col text-center" style={{ paddingTop: "1px" }}>
                  <img src="/assets/img/logo.png" alt="komtrax logo" />
                </div>
                <div className="col d-flex justify-content-end">
                  <button
                    className="header-btn"
                    onClick={
                      () =>
                        window.open(
                          "https://www.google.com/maps/dir/Current+Location/" +
                            data.position.lat +
                            "," +
                            data.position.lng +
                            "",
                          "_blank" // <- This is what makes it open in a new window.
                        )
                      // (window.location =
                      //   "https://www.google.com/maps/dir/Current+Location/" +
                      //   data.position.lat +
                      //   "," +
                      //   data.position.lng +
                      //   "")
                    }
                  >
                    <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <BsBoxArrowInUpRight />
                    </IconContext.Provider>
                  </button>
                </div>
              </div>
              {data === null ? (
                "Loading..."
              ) : (
                <div className="row">
                  <div className="col-12">
                    <DirectionMap Data={data} direction={true} />
                    {/* <MapContainer
                      height={"70vh"}
                      width={"100%"}
                      MData={data}
                      direction={true}
                    /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrivingDirection;
