import React from "react";
import Switch from "react-switch";

const SwitchButton = ({ isSwitchToggled, handleToggle }) => {
  return (
    <>
      <Switch
        checked={isSwitchToggled}
        onChange={handleToggle}
        handleDiameter={25}
        offColor="#888888"
        onColor="#2693E6"
        offHandleColor="#fff"
        onHandleColor="#fff"
        height={33}
        width={68}
        borderRadius={6}
        // activeBoxShadow="0px 0px 1px 2px #fffc35"
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 15,
              color: "white",
              paddingRight: 2,
            }}
          >
            Off
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 15,
              color: "white",
              paddingRight: 2,
            }}
          >
            On
          </div>
        }
        // uncheckedHandleIcon={
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       height: "100%",
        //       fontSize: 20,
        //     }}
        //   >
        //     ☹
        //   </div>
        // }
        // checkedHandleIcon={
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       height: "100%",
        //       color: "red",
        //       fontSize: 18,
        //     }}
        //   >
        //     ♥
        //   </div>
        // }
        className="react-switch"
        id="small-radius-switch"
      />
    </>
  );
};

export default SwitchButton;
