const inputDateFormat = "YYYYMMDD";
const inputDateTimeFormat = "YYYYMMDDHHmmss";
const outputDateRangeFormat = "YYYY-MM-DD";
const outputDateWithoutRangeFormat = "MM-DD-YYYY";
const outputDateFormat = "DD/MM/YYYY";
const outputDateTimeFormat = "DD/MM/YYYY HH:mm:ss";

export {
  inputDateFormat,
  inputDateTimeFormat,
  outputDateFormat,
  outputDateTimeFormat,
  outputDateRangeFormat,
  outputDateWithoutRangeFormat,
};
