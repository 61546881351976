import React, { useState, useEffect } from "react";
import AuthService from "../../web-services/authService";
import { useNavigate } from "react-router";
import "./login.scss";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import FilterService from "../../web-services/filterService";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [customerName, setCustomerName] = useState("");
  const [lang, setLang] = useState("EN");
  const [deviceType, setDeviceType] = useState("PC");
  const [ipAdd, setIpAdd] = useState("Ip Not Available");
  const [response, setResponse] = useState({});
  const [cautions, setCautions] = useState({});
  const [resources, setResources] = useState({});
  const [initialSettings, setInitialSettings] = useState({});
  const [language, setLanguage] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [errors, setErrors] = useState({});

  const [lat, setLat] = useState(
    localStorage.getItem("Current_Location")
      ? JSON.parse(localStorage.getItem("Current_Location")).lat
      : -33.865143
  );
  const [lng, setLng] = useState(
    localStorage.getItem("Current_Location")
      ? JSON.parse(localStorage.getItem("Current_Location")).lng
      : 151.2099
  );

  const fetchData = async () => {
    try {
      const response1 = await AuthService.getCautions(lang);
      setCautions(response1.ServiceValueResponse);
      localStorage.setItem(
        "Cautions",
        JSON.stringify(response1.ServiceValueResponse.Return.Cautions)
      );
    } catch (e) {
      setError(e);
    }

    try {
      const response2 = await AuthService.getResources(lang);
      setResources(response2);
      localStorage.setItem("Resources", JSON.stringify(response2.Return));
    } catch (e) {
      setError(e);
    }

    try {
      const response3 = await AuthService.getInitialSettings();
      setInitialSettings(response3);
      localStorage.setItem(
        "ShowUN",
        JSON.stringify(response3.Return.Setting.ShowUN)
      );
      localStorage.setItem("Unit", response3.Return.Setting.Unit);
      localStorage.setItem(
        "Lang",
        JSON.stringify(response3.Return.Setting.Lang)
      );
      localStorage.setItem(
        "DateFormat",
        JSON.stringify(response3.Return.Setting.DateFormat)
      );
      localStorage.setItem("Fuel_Ltr_Hr", false);
    } catch (e) {
      setError(e);
    }

    try {
      const response4 = await AuthService.getLanguage();
      setLanguage(response4.ServiceValueResponse);
      localStorage.setItem("Languages_Pack", JSON.stringify(response4.Return));
    } catch (e) {
      setError(e);
    }
  };

  const handelLogin = async () => {
    // authenticate();
    setLoading(true);
    fetchData();
    try {
      const response5 = await AuthService.login(
        `Get_AzureAD_Login?Customer_Name=${
          customerName !== ""
            ? customerName
            : localStorage.getItem("CustomerName")
        }&Lang=${lang}&Device_Type=${deviceType}&Remote_Host=${ipAdd}`
      );
      localStorage.setItem(
        "contact",
        JSON.stringify(response5.ServiceValueResponse.Return.Data)
      );
      localStorage.setItem(
        "FuelDissolution",
        response5.ServiceValueResponse.Return.Settings.FuelDissolution
      );
      localStorage.setItem(
        "ConvL2G",
        response5.ServiceValueResponse.Return.Settings.ConvL2G
      );
      localStorage.setItem(
        "UId",
        response5.ServiceValueResponse.Return.User_Id
      );
      localStorage.setItem(
        "User_number",
        response5.ServiceValueResponse.Return.User_Id
      );
      localStorage.setItem(
        "User_name",
        response5.ServiceValueResponse.Return.User_Name
      );
      localStorage.setItem(
        "Customer_Name",
        response5.ServiceValueResponse.Return.Customer_Name
      );
      localStorage.setItem(
        "customer_number",
        response5.ServiceValueResponse.Return.Customer_Id
      );
      setCustomerID(response5.ServiceValueResponse.Return.Customer_Id);
      localStorage.setItem("Tel", response5.ServiceValueResponse.Return.Tel);
      localStorage.setItem(
        "Email",
        response5.ServiceValueResponse.Return.MailAddress
      );
      try {
        const response6 = await AuthService.toc();
        setLoading(false);
        const toc_status = localStorage.getItem("show-agreement");
        if (
          toc_status &&
          response5.ServiceValueResponse.Return.Customer_Id === "0"
        ) {
          navigate("./filter");
        } else if (response5.ServiceValueResponse.Return.Customer_Id !== "0") {
          applyFilter();
          navigate("/results");
        } else {
          navigate("terms-of-use", {
            state: response6.Return,
          });
        }
      } catch (e) {
        setErrors(e);
      }
    } catch (e) {
      setErrors(e);
    }
  };

  const applyFilter = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await FilterService.filter(
        customerID,
        null,
        true,
        0,
        lat,
        lng
      );
      if (response.ServiceValueResponse.Succeeded === true) {
        localStorage.setItem(
          "machinesData",
          JSON.stringify(response.ServiceValueResponse.Return)
        );
        setLoading(false);
        navigate("/results", {
          state: response.ServiceValueResponse.Return,
        });
      } else if (response.ServiceValueResponse.Code === 204) {
        alert(response.ServiceValueResponse.DisplayMessage);
        setLoading(false);
      }
    } catch (e) {
      if (e.response.status == 400) {
        setErrors(e.response.data.errors);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (accounts[0]) {
      setCustomerName(accounts[0].username);
      localStorage.setItem("CustomerName", accounts[0].username);
      navigator.geolocation.getCurrentPosition(function (position) {
        const tempObj = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        localStorage.setItem(
          "Current_Location",

          JSON.stringify(tempObj)
        );
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (isAuthenticated) {
      // setLoading(true);
      handelLogin();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (Object.keys(error).length != 0) {
      setLoading(false);
      alert(error);
    }
    if (Object.keys(errors).length != 0) {
      setLoading(false);
      alert(errors);
    }
  }, [error, errors]);

  return (
    <>
      <div className="App">
        <div className="login">
          <div className="container-fluid">
            <div className="row mx-1">
              <div className="col-md-6 offset-md-3 main-content-login p-0">
                <div className="row m-0  text-center login-header">
                  <div className="col my-1">
                    <img src="/assets/img/logo.png" alt="komtrax logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 offset-md-3 p-0">
                <div className="row m-0 ">
                  <div className="col bg-white login-form p-3">
                    <form>
                      {isAuthenticated ? (
                        <>
                          <button
                            className="text-center my-3 w-100 login-btn"
                            //onClick={handelLogin}
                          >
                            Login
                          </button>
                        </>
                      ) : (
                        <SignInButton />
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid back-main">
        <div className="row g-0 bg-white border rounded">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
            <img
              src="/assets/img/login-page.jpg"
              alt="login-img"
              width="100%"
            />
          </div>
          <div className="col-md-8 col-lg-6 px-3">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <h3 className="login-heading mb-2">Welcome back!</h3>

                    <form>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="User ID"
                        />
                        <label for="floatingInput">User ID</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                        />
                        <label for="floatingPassword">Password</label>
                      </div>

                      <div className="d-grid">
                        <button
                          className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
                          type="submit"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Login;
