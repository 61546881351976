import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TokenBasedMiddleware from "./routes/TokenBasedMiddleware";
import { authProtectedRoutes, publicRoutes } from "./routes/index";
import { useIsAuthenticated } from "@azure/msal-react";
import { passiveSupport } from "passive-events-support/src/utils";
passiveSupport({
  debug: false,
  listeners: [
    {
      element: "div",
      event: "mousewheel",
      prevented: true,
    },
    {
      element: "div",
      event: "touchstart",
      prevented: true,
    },
    {
      element: "div",
      event: "wheel",
      prevented: true,
    },
    {
      element: "div",
      event: "touchend",
      prevented: true,
    },
    {
      element: "div",
      event: "touchmove",
      prevented: true,
    },
  ],
});

function App() {
  const isAuthenticated = useIsAuthenticated();
  console.disableYellowBox = true;
  console.warn = () => {};

  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((publicRoute, i) => (
          <Route
            key={i}
            exact
            path={publicRoute.path}
            element={publicRoute.element}
          />
        ))}
        <Route
          exact
          path="/"
          element={
            <TokenBasedMiddleware
              isProtected={true}
              isAuthenticated={isAuthenticated}
            />
          }
        >
          {authProtectedRoutes.map((protectedRoute, i) => (
            <Route
              key={i}
              path={protectedRoute.path}
              element={protectedRoute.element}
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// import { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import "./App.css";
// import Login from "./Pages/Auth/Login";
// import MainFilter from "./Pages/Filter/MainFilter";
// import Preferences from "./Pages/Preferences/Preferences";
// import FilterResult from "./Pages/FilterResult/FilterResult";
// import StatsMain from "./Pages/Stats/StatsMain";

// function App() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     const u = localStorage.getItem("LoggedIn");
//     u && JSON.parse(u) ? setIsLoggedIn(true) : setIsLoggedIn(false);
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("LoggedIn", isLoggedIn);
//   }, [isLoggedIn]);

//   return (
//     <>
//       {isLoggedIn ? (
//         <Router>
//           <Routes>
//             <Route path="/filter" element={<MainFilter />} />
//             <Route
//               path="prefrence"
//               element={<Preferences logout={() => setIsLoggedIn(false)} />}
//             />
//             <Route path="/results" element={<FilterResult />} />
//             <Route path="/woring-hours" element={<StatsMain />} />
//             <Route
//               path="/"
//               element={<Navigate to={isLoggedIn ? "/filter" : "/login"} />}
//             />
//           </Routes>
//         </Router>
//       ) : (
//         <Router>
//           <Routes>
//             <Route
//               path="/login"
//               element={<Login authenticate={() => setIsLoggedIn(true)} />}
//             />
//             <Route
//               path="/"
//               element={<Navigate to={isLoggedIn ? "/filter" : "/login"} />}
//             />
//           </Routes>
//         </Router>
//       )}
//     </>
//   );
// }

// export default App;
