import React from "react";
import { FiSettings } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import MapContainer, { initMap } from "../../Components/Map";
import { useEffect, useState, useRef } from "react";
import useWindowDimensions from "../../GetDimenssions/GetWindowSize";
import { useLayoutEffect } from "react";
import { passiveSupport } from "passive-events-support/src/utils";
passiveSupport({
  debug: false,
  listeners: [
    {
      element: "document",
      event: "touchstart",
      prevented: true,
    },
    {
      element: "div",
      event: "touchstart",
      prevented: true,
    },
    {
      element: "div#root",
      event: "touchend",
      prevented: true,
    },
    {
      element: "div",
      event: "touchend",
      prevented: true,
    },
    {
      element: "div",
      event: "touchmove",
      prevented: true,
    },
  ],
});

const FilterResult = () => {
  let res = [];
  let upperContentHeight;
  const navigate = useNavigate();
  const { state } = useLocation();
  const ref = useRef(null);
  const { height, width } = useWindowDimensions();

  const [result, setResult] = useState({});
  const [MData, setMData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [touchtime, setTouchTime] = useState(0);
  const [goDirection, setGoDirection] = useState(false);
  const [selected, setSelected] = useState({});
  const [mapHeight, setMapHeight] = useState(0);

  const tap = (id) => {
    handleSingleClick(id);

    if (touchtime == 0) {
      setTouchTime(new Date().getTime());
    } else {
      if (new Date().getTime() - touchtime < 400) {
        var tempId = id;
        if (tempId === id) {
          handleDoubleClick(id);
          setTouchTime(0);
        }
      } else {
        setTouchTime(new Date().getTime());
      }
    }
  };

  const handleDoubleClick = (id) => {
    navigate(`/results/${id}`, { state: state });
  };

  const handleSingleClick = (id) => {
    drawMap("Home", id, "");
    const alreadySelected = document.querySelector(".selected-row");
    if (alreadySelected) {
      alreadySelected.classList.remove("selected-row");
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    } else {
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    }
  };

  var markersArray = [];
  var Data = [];
  var count = 1;
  var isHome = false;
  function drawMap(Screen, Row, MachineID, SerialNumber, Machine_data) {
    setLoading(true);
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    var LatestDate = MachineData.LatestDate + "000000";
    var CautionList = MachineData.CautionList;
    var caution = 0;
    var AbnormalityList = MachineData.AbnormalityList;
    var j = 0;
    if (localStorage.getItem("Desired_Location") !== null) {
      //Delete previous deatination location from local storage
      localStorage.removeItem("Desired_Location");
    }

    var LatestDate = MachineData.LatestDate;
    var Icon;
    var marker;
    if (Screen == "Home" && Row == "working-hours") {
      if (MachineID == "Initialize") {
        //this if is used o initilaze map for Working-hour Detailed screen
        //   initialize();
      } else {
        Data.length = 0; // Delete the previous data
        for (j = 0; j < MachineData.Machines.length; j++) {
          var found = false;
          //Eliminate Machine with No comm and No Usage
          if (
            LatestDate > MachineData.Machines[j].LatestComDate ||
            (LatestDate > MachineData.Machines[j].LastWorkDate + "000000" &&
              LatestDate <= MachineData.Machines[j].LatestComDate)
          ) {
            continue;
          }

          for (var i = 0; i < CautionList.length; i++) {
            var Date = LatestDate - CautionList[i].Date; //Caution Date
            if (Date <= 0) {
              for (var k = 0; k < CautionList[i].Machines.length; k++) {
                if (
                  MachineData.Machines[j].MachineId ==
                  CautionList[i].Machines[k].MachineID
                ) {
                  found = true;
                }
              }
            }
          }
          if (found == true) {
            continue;
          }
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,

              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_green.png",
            });
          }
        }
      }
    } else if (Screen == "Home" && Row == "cautions") {
      Data.length = 0; // Delete the previous data
      if (MachineID == "Initialize") {
        //this if is used o initilaze map for Working-hour Detailed screen
      } else {
        for (i = 0; i < CautionList.length; i++) {
          var Date = LatestDate - CautionList[i].Date; //Caution Date
          if (Date <= 0) {
            for (var m = 0; m < CautionList[i].Machines.length; m++) {
              //Number of machines in a specific date
              var MachineId = CautionList[i].Machines[m].MachineID;
              for (x = 0; x < MachineData.Machines.length; x++) {
                //Serach for Machine id in Machine List
                if (MachineData.Machines[x].MachineId == MachineId) {
                  // if machine is found push it on Data Array
                  if (MachineData.Machines[x].GPSDate !== null) {
                    Data.push({
                      Model:
                        MachineData.Machines[x].Model +
                        "-" +
                        MachineData.Machines[j].Type,
                      SerialNumber: MachineData.Machines[x].SerialNumber,
                      Lat: MachineData.Machines[x].Location.Lat,
                      Long: MachineData.Machines[x].Location.Long,
                      CustomerName: MachineData.Machines[x].CustomerDesc,
                      SMR: MachineData.Machines[x].SMR,
                      LastCommDate: MachineData.Machines[x].LatestComDate,
                      GPSDate: MachineData.Machines[x].GPSDate,
                      Icon: "/assets/img/pin_yellow.png",
                    });
                  }
                }
              }
            }
          }
        }
      }
    } else if (Screen == "Home" && Row == "no-comm") {
      Data.length = 0; // Delete the previous data
      if (MachineID == "Initialize") {
        //this if is used o initilaze map for Working-hour Detailed screen
      }
      for (j = 0; j < MachineData.Machines.length; j++) {
        if (LatestDate > MachineData.Machines[j].LatestComDate) {
          //All machines that communicate before Latest Date
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_gray.png",
            });
          }
        }
      }
    } else if (Screen == "Home" && Row == "no-usage") {
      Data.length = 0; // Delete the previous data
      if (MachineID == "Initialize") {
        //this if is used to initilaze map for Working-hour Detailed screen
      }
      for (j = 0; j < MachineData.Machines.length; j++) {
        if (
          LatestDate > MachineData.Machines[j].LastWorkDate + "000000" &&
          LatestDate <= MachineData.Machines[j].LatestComDate
        )
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_purple.png",
            });
          }
      }
    } else if (Screen == "Home" && Row == "Movements") {
    } else if (Screen == "Home" && Row == "abnormalities") {
      /* Getting machines locations with abnormalities and insert it into data array*/
      Data.length = 0; // Delete the previous data
      if (MachineID == "Initialize") {
        //this if is used o initilaze map for Working-hour Detailed screen
      } else {
        for (i = 0; i < AbnormalityList.length; i++) {
          var datepart = AbnormalityList[i].Date.split(" ")[0];
          var Date = LatestDate - datepart;
          if (Date <= 0) {
            for (var m = 0; m < AbnormalityList[i].Machines.length; m++) {
              var MachineId = AbnormalityList[i].Machines[m].MachineID;
              for (x = 0; x < MachineData.Machines.length; x++) {
                if (MachineData.Machines[x].MachineId == MachineId) {
                  if (MachineData.Machines[x].GPSDate !== null) {
                    Data.push({
                      Model:
                        MachineData.Machines[x].Model +
                        "-" +
                        MachineData.Machines[j].Type,
                      SerialNumber: MachineData.Machines[x].SerialNumber,
                      Lat: MachineData.Machines[x].Location.Lat,
                      Long: MachineData.Machines[x].Location.Long,
                      CustomerName: MachineData.Machines[x].CustomerDesc,
                      SMR: MachineData.Machines[x].SMR,
                      LastCommDate: MachineData.Machines[x].LatestComDate,
                      GPSDate: MachineData.Machines[x].GPSDate,
                      Icon: "/assets/img/pin_red.png",
                    });
                  }
                }
              }
            }
          }
        }
      }
    } else if (Screen == "Home" && Row == "Main") {
      // if not machine is selected i.e. Start of the app
      count = 1;
      isHome = true;
      // initialize();
      var centerControlDiv = document.createElement("div");
      // var centerControl = new CenterControl(centerControlDiv, map);
      centerControlDiv.index = 1;
      // map.controls[google.maps.ControlPosition.RIGHT].push(centerControlDiv);

      Data.length = 0; // Delete the previous data
      for (j = 0; j < MachineData.Machines.length; j++) {
        if (LatestDate > MachineData.Machines[j].LatestComDate) {
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_Gray.png",
            });
          }
        } else if (
          LatestDate > MachineData.Machines[j].LastWorkDate + "000000" &&
          LatestDate <= MachineData.Machines[j].LatestComDate
        ) {
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_purple.png",
            });
          }
        }
      }

      for (i = 0; i < CautionList.length; i++) {
        var Date = LatestDate - CautionList[i].Date; //Caution Date

        if (Date <= 0) {
          for (var m = 0; m < CautionList[i].Machines.length; m++) {
            //Number of machines in a specific date
            var MachineId = CautionList[i].Machines[m].MachineID;
            for (x = 0; x < MachineData.Machines.length; x++) {
              //Serach for Machine id in Machine List
              if (MachineData.Machines[x].MachineId == MachineId) {
                if (MachineData.Machines[x].GPSDate !== null) {
                  Data.push({
                    Model:
                      MachineData.Machines[x].Model +
                      "-" +
                      MachineData.Machines[x].Type,
                    SerialNumber: MachineData.Machines[x].SerialNumber,
                    Lat: MachineData.Machines[x].Location.Lat,
                    Long: MachineData.Machines[x].Location.Long,
                    CustomerName: MachineData.Machines[x].CustomerDesc,
                    SMR: MachineData.Machines[x].SMR,
                    LastCommDate: MachineData.Machines[x].LatestComDate,
                    GPSDate: MachineData.Machines[x].GPSDate,
                    Icon: "/assets/img/pin_yellow.png",
                  });
                }
              }
            }
          }
        }
      }

      for (i = 0; i < AbnormalityList.length; i++) {
        var datepart = AbnormalityList[i].Date.split(" ")[0];
        var Date = LatestDate - datepart;
        if (Date <= 0) {
          for (var m = 0; m < AbnormalityList[i].Machines.length; m++) {
            var MachineId = AbnormalityList[i].Machines[m].MachineID;
            for (x = 0; x < MachineData.Machines.length; x++) {
              if (MachineData.Machines[x].MachineId == MachineId) {
                if (MachineData.Machines[x].GPSDate !== null) {
                  Data.push({
                    Model:
                      MachineData.Machines[x].Model +
                      "-" +
                      MachineData.Machines[x].Type,
                    SerialNumber: MachineData.Machines[x].SerialNumber,
                    Lat: MachineData.Machines[x].Location.Lat,
                    Long: MachineData.Machines[x].Location.Long,
                    CustomerName: MachineData.Machines[x].CustomerDesc,
                    SMR: MachineData.Machines[x].SMR,
                    LastCommDate: MachineData.Machines[x].LatestComDate,
                    GPSDate: MachineData.Machines[x].GPSDate,
                    Icon: "/assets/img/pin_red.png",
                  });
                }
              }
            }
          }
        }
      }

      for (var x = 0; x < MachineData.Machines.length; x++) {
        var Found = false;
        Data.forEach((item) => {
          if (MachineData.Machines[x].SerialNumber == item.SerialNumber) {
            Found = true;
          }
        });

        if (Found == false) {
          if (MachineData.Machines[x].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[x].Model +
                "-" +
                MachineData.Machines[x].Type,
              SerialNumber: MachineData.Machines[x].SerialNumber,
              Lat: MachineData.Machines[x].Location.Lat,
              Long: MachineData.Machines[x].Location.Long,
              CustomerName: MachineData.Machines[x].CustomerDesc,
              SMR: MachineData.Machines[x].SMR,
              LastCommDate: MachineData.Machines[x].LatestComDate,
              GPSDate: MachineData.Machines[x].GPSDate,
              Icon: "/assets/img/pin_green.png",
            });
          }
        }
      }
    } else if (Screen == "Home" && Row == "empty") {
      //call when click on home grid row and there is no machine to show i.e stops flucuation of map

      Data.length = 0;
      for (var i = 0; i < markersArray.length; i++) {
        markersArray[i].setMap(null);
      }
      markersArray.length = 0;
    } else if (Screen == "working-hours" && Row == "True") {
      Data.length = 0;
      for (var j = 0; j < MachineData.Machines.length; j++) {
        if (MachineData.Machines[j].SerialNumber == SerialNumber) {
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_green.png",
            });
          }
        }
      }
    }
    markersArray = Data;
    setMData(markersArray);
    setLoading(false);
  }

  function FormateDate(date, time) {
    if (date !== null) {
      if (time) {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var hr = date.slice(9, 11);
        var minu = date.slice(11, 13);
        var dte = dd + "/" + mm + "/" + yyyy + " " + hr + ":" + minu + "Hrs";
        return dte;
      } else {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var dte = dd + "/" + mm + "/" + yyyy;
        return dte;
      }
    }
  }

  useEffect(() => {
    let tempArr = [];
    if (localStorage.getItem("machinesData")) {
      setResult(JSON.parse(localStorage.getItem("machinesData")));
      drawMap("Home", "Main", "");
    } else {
      setResult(state);
      drawMap("Home", "Main", "");
    }
  }, []);

  useEffect(() => {
    if (goDirection) {
      // <Link to="/driving-direction" target="_blank"></Link>;
      navigate("/driving-direction");
    }
  }, [goDirection]);

  useLayoutEffect(() => {
    upperContentHeight = ref.current.clientHeight;
    setMapHeight(height - upperContentHeight);
  }, [height]);

  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <div ref={ref}>
                <div className="row filter-header m-0 d-flex justify-content-between align-items-center">
                  <div className="col">
                    <button
                      className="header-btn"
                      onClick={() => navigate("/filter")}
                    >
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <BsSearch />
                        {width <= 335 ? "" : " Filter"}
                      </IconContext.Provider>
                    </button>
                  </div>
                  <div
                    className="col text-center"
                    style={{ paddingTop: "1px" }}
                  >
                    <img src="/assets/img/logo.png" alt="komtrax-log" />
                  </div>

                  <div className="col d-flex justify-content-end">
                    <button
                      className="header-btn"
                      onClick={() => navigate("/prefrence")}
                    >
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <FiSettings />
                        {width <= 335 ? "" : " Prefs"}
                      </IconContext.Provider>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Table>
                      <thead className="fltr-table-head">
                        <tr>
                          <th>Status</th>
                          <th>
                            {Object.keys(result).length !== 0
                              ? `${result.Machines.length} / ${result.MachineCount} (Fleet count)`
                              : ""}
                          </th>
                          <th>1D</th>
                          <th>1W</th>
                          <th>1M</th>
                        </tr>
                      </thead>
                      <tbody className="fltr-table-body">
                        <tr
                          onClick={() => tap("working-hours")}
                          // onDoubleClick={() => handleDoubleClick("working-hours")}
                          // onClick={() => handleSingleClick("working-hours")}
                          id={"working-hours"}
                        >
                          <td>
                            <img
                              src="/assets/img/icon_green.png"
                              width="22px"
                              height="22px"
                            />
                          </td>
                          <td>Working hours</td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[0].D}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[0].W}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[0].M}
                          </td>
                        </tr>
                        <tr
                          onClick={() => tap("cautions")}
                          // onDoubleClick={() => handleDoubleClick("cautions")}
                          // onClick={() => handleSingleClick("cautions")}
                          id={"cautions"}
                        >
                          <td>
                            <img
                              src="/assets/img/icon_yellow.png"
                              width="22px"
                              height="22px"
                            />
                          </td>
                          <td>Caution</td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[1].D}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[1].W}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[1].M}
                          </td>
                        </tr>
                        <tr
                          onClick={() => tap("abnormalities")}
                          // onDoubleClick={() => handleDoubleClick("abnormalities")}
                          // onClick={() => handleSingleClick("abnormalities")}
                          id={"abnormalities"}
                        >
                          <td>
                            <img
                              src="/assets/img/icon_red.png"
                              width="22px"
                              height="22px"
                            />
                          </td>
                          <td>Abnormality</td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[5].D}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[5].W}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[5].M}
                          </td>
                        </tr>
                        <tr
                          onClick={() => tap("no-comm")}
                          // onDoubleClick={() => handleDoubleClick("no-comm")}
                          // onClick={() => handleSingleClick("no-comm")}
                          id={"no-comm"}
                        >
                          <td>
                            <img
                              src="/assets/img/icon_gray.png"
                              width="22px"
                              height="22px"
                            />
                          </td>
                          <td>No communication</td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[2].D}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[2].W}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[2].M}
                          </td>
                        </tr>
                        <tr
                          onClick={() => tap("no-usage")}
                          // onDoubleClick={() => handleDoubleClick("no-usage")}
                          // onClick={() => handleSingleClick("no-usage")}
                          id={"no-usage"}
                        >
                          <td>
                            <img
                              src="/assets/img/icon_purple.png"
                              width="22px"
                              height="22px"
                            />
                          </td>
                          <td>No usage</td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[3].D}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[3].W}
                          </td>
                          <td>
                            {Object.keys(result).length === 0
                              ? "--"
                              : result.Summary[3].M}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div
                  style={width >= 325 ? { height: "7px" } : { height: "10px" }}
                ></div>
              </div>
              <div className="row">
                <div className="col">
                  {loading && upperContentHeight == 0 ? (
                    <div>Loading..!!</div>
                  ) : (
                    <MapContainer
                      setGoDirection={setGoDirection}
                      setSelected={setSelected}
                      selected={selected}
                      MData={MData.length === 0 ? null : MData}
                      height={mapHeight}
                      width={"100%"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterResult;
