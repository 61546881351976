import API from "./api";

const AuthService = {
  getCautions: (lang) => {
    return API.get(`Get_Cautions?lang=${lang}`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getResources: (lang) => {
    return API.get(`Get_Resources?option=${lang}`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getInitialSettings: () => {
    return API.get(`Get_InitialSettings`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getLanguage: () => {
    return API.get(`Get_Languages`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  login: (url) => {
    return API.get(url)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  toc: () => {
    return API.get(`Get_TOC`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default AuthService;
