import API from "./api";

const FilterService = {
  modelsFroRadius: (cus_id, radi, lat, lng) => {
    return API.get(
      `Get_ModelsForRadius?customer_id=${cus_id}&radius=${radi}&lat=${lat}&lng=${lng}`
    )
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  customerList: (input) => {
    return API.get(`Get_CustomersList?Customer_Name=${input}`)
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  filter: (id, model, kal, radius, lat, lng, srNum, unitNum) => {
    return API.get(
      `Get_Machines?cus_id=${id}&Model=${model}&serial=${srNum}&unit=${unitNum}&lang=${"EN"}&Kal=${kal}&Radius=${radius}&Lat=${lat}&Lan=${lng}`
    )
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  contactOperator: (formData) => {
    return API.get(
      `Contact_Service?UserId=${formData.UserId}&MachineId=${formData.MachineId}&Desc=${formData.Desc}&Date=${formData.Date}&SMR=${formData.SMR}&Code=${formData.Code}&Frequency=${formData.Frequency}&ModelType=${formData.ModelType}&CustomerDesc=${formData.CustomerDesc}&Module=${formData.Module}&LatLng=${formData.LatLng}&UserName=${formData.UserName}&EmailId=${formData.EmailId}&Tel=${formData.Tel}&SerialNo=${formData.SerialNo}`
    )
      .then(({ data }) => {
        // debugger
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default FilterService;
