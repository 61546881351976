import Login from "../Pages/Auth/Login";
import MainFilter from "../Pages/Filter/MainFilter";
import Preferences from "../Pages/Preferences/Preferences";
import FilterResult from "../Pages/FilterResult/FilterResult";
import WorkingHours from "../Pages/Stats/WorkingHours";
import Cautions from "../Pages/Stats/Cautions";
import Abnormalities from "../Pages/Stats/Abnormalities";
import NoComm from "../Pages/Stats/NoComm";
import NoUsage from "../Pages/Stats/NoUsage";
import TermsOfCondition from "../Pages/Toc/TermsOfCondition";
import DrivingDirection from "../Pages/MapDirection/DrivingDirection";
import RedirectToLogin from "../Pages/RedirectPage/RedirectToLogin";
import RedirectToStaffLogin from "../Pages/RedirectPage/RedirectToStaffLogin";

const publicRoutes = [
  { path: "/", element: <Login /> },
  { path: "/terms-of-use", element: <TermsOfCondition /> },
  { path: "/login/", element: <RedirectToLogin /> },
  { path: "/staff-login/", element: <RedirectToStaffLogin /> },
];
const authProtectedRoutes = [
  { path: "/filter", element: <MainFilter /> },
  { path: "/prefrence", element: <Preferences /> },
  { path: "/results", element: <FilterResult /> },
  { path: "/results/working-hours", element: <WorkingHours /> },
  { path: "/results/cautions", element: <Cautions /> },
  { path: "/results/abnormalities", element: <Abnormalities /> },
  { path: "/results/no-comm", element: <NoComm /> },
  { path: "/results/no-usage", element: <NoUsage /> },
  { path: "/driving-direction", element: <DrivingDirection /> },
];

export { publicRoutes, authProtectedRoutes };
