import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router";
import SwitchButton from "./Switch";
import { SignOutButton } from "../Auth/components/SignOutButton";

const Preferences = () => {
  const navigate = useNavigate();
  const [isSwitchToggledUN, setIsSwitchToggledUN] = useState(
    localStorage.getItem("ShowUN") === "true" ? true : false
  );
  const [isSwitchToggledFuel, setIsSwitchToggledFuel] = useState(
    localStorage.getItem("Fuel_Ltr_Hr") === "true" ? true : false
  );
  const [languages, setLanguages] = useState(
    JSON.parse(localStorage.getItem("Languages_Pack")).Langs
  );

  const handleToggleUN = () => {
    if (!isSwitchToggledUN) {
      setIsSwitchToggledUN(true);
      localStorage.setItem("ShowUN", true);
    } else {
      setIsSwitchToggledUN(false);
      localStorage.setItem("ShowUN", false);
    }
  };
  const handleToggleFuel = () => {
    if (!isSwitchToggledFuel) {
      setIsSwitchToggledFuel(true);
      localStorage.setItem("Fuel_Ltr_Hr", true);
    } else {
      setIsSwitchToggledFuel(false);
      localStorage.setItem("Fuel_Ltr_Hr", false);
    }
  };

  // useEffect(() => {
  //   setIsSwitchToggledUN(localStorage.getItem("ShowUN"));
  // }, []);

  return (
    <>
      <div className="App">
        <div className="container px-0">
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              <div
                className="row filter-header m-0 d-flex justify-content-between align-items-center"
                style={{ height: "45px" }}
              >
                <div className="col"></div>
                <div className="col text-center p-1">Preferences</div>
                <div className="col"></div>
              </div>
              <div className="heading-bar my-2">Option</div>
              {/*<div className="form-group mx-3 bg-white">
                <label className="bg-white label mb-1" htmlFor="exampleInput">
                  Language
                </label>
                <select className="form-select bg-white">
                  {languages.length !== 0 &&
                    languages.map((item) => (
                      <option key={item.lang}>{item.Name}</option>
                    ))}
                </select>
              </div>
              <hr className="mx-3 " />
              <div className="form-group mx-3 my-2 bg-white ">
                <label className="bg-white label mb-1" htmlFor="exampleInput">
                  Date format
                </label>
                <select className="form-select bg-white">
                  <option>DD/MM/YYYY</option>
                  <option>MM/DD/YYYY</option>
                  <option>YYYY/MM/DD</option>
                </select>
              </div>
              <hr className="mx-3" />
              <div className="form-group mx-3 my-1 bg-white">
                <label className="bg-white label mb-1" htmlFor="exampleInput">
                  Units
                </label>
                <select className="form-select bg-white">
                  <option>Metric</option>
                  <option>Imperial</option>
                </select>
              </div>
              <hr className="mx-3" />*/}
              <div className="row my-3">
                <div className="col-12 my-3">
                  <div className="row form-check form-switch p-0 mx-2 d-flex">
                    <div className="col m-0">
                      <label
                        className="bg-white label form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Show unit number
                        <p style={{ fontSize: "10px" }}>
                          Show unit instead of serial when available
                        </p>
                      </label>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <SwitchButton
                        handleToggle={handleToggleUN}
                        isSwitchToggled={isSwitchToggledUN}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 my-2">
                  <div className="row form-check form-switch p-0 mx-2 d-flex">
                    <div className="col m-0">
                      <label
                        className="bg-white label mb-1 form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Fuel per hour
                        <p style={{ fontSize: "10px" }}>
                          Show fuel per hour (L/hr) instead of fuel consumption
                          (L)
                        </p>
                      </label>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <SwitchButton
                        handleToggle={handleToggleFuel}
                        isSwitchToggled={isSwitchToggledFuel}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="heading-bar mt-0">Account</div> */}
              <hr className="mx-3" />
              <div className="row my-3">
                <div className="col d-flex justify-content-end">
                  <SignOutButton />
                </div>
                <div className="col d-flex justify-content-start">
                  <button className="fltr-btn" onClick={() => navigate(-1)}>
                    {/* <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <FaArrowLeft />
                    </IconContext.Provider> */}
                    Done
                  </button>
                </div>
                {/* <button className="logoff-btn" onClick={hanleLogout}>
                  Logoff
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
