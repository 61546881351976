import React from "react";
import { Navigate, Outlet } from "react-router-dom";

//Navigate Replaces Redirect but has core changes

const TokenBasedMiddleware = ({ isProtected, isAuthenticated }) => {
  return isAuthenticated &&
    isProtected &&
    localStorage.getItem("show-agreement") ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

export default TokenBasedMiddleware;
