import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./filter.scss";
import FilterService from "../../web-services/filterService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const MainFilter = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [models, setModels] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [serialNum, setSerialNum] = useState("");
  const [unitNum, setUnitNum] = useState("");
  const [customerID, setCustomerID] = useState(
    localStorage.getItem("customer_number")
  );
  const [selectedCustomer, setSelectedCustomer] = useState({
    value: 0,
    label: "Type 3 characters",
  });
  const [selectedModel, setSelectedModel] = useState({
    value: null,
    label: null,
  });
  const [radiusOptions, setRadiusOptions] = useState([
    { label: "All", value: 0 },
    { label: "5 km", value: 1 },
    { label: "10 km", value: 2 },
    { label: "15 km", value: 3 },
    { label: "20 km", value: 4 },
    { label: "40 km", value: 5 },
  ]);
  const [radius, setRadius] = useState({ label: "All", value: 0 });
  const [lat, setLat] = useState(
    localStorage.getItem("Current_Location")
      ? JSON.parse(localStorage.getItem("Current_Location")).lat
      : -33.865143
  );
  const [lng, setLng] = useState(
    localStorage.getItem("Current_Location")
      ? JSON.parse(localStorage.getItem("Current_Location")).lng
      : 151.2099
  );
  const [kal, setKal] = useState(true);
  const [cutomerLoading, setCutomerLoading] = useState(false);
  const [machineloading, setMachineLoading] = useState(false);

  const getModelForRadius = async (cusID) => {
    setMachineLoading(true);
    setLoading(true);
    try {
      const response = await FilterService.modelsFroRadius(
        cusID,
        radius.value,
        lat,
        lng
      );
      const tempArr = [];
      if (response.ServiceValueResponse.Return.ModelType.length === 0) {
        setModels([
          {
            value: null,
            label: "-",
          },
        ]);
      } else {
        response.ServiceValueResponse.Return.ModelType.map((item, i) => {
          tempArr.push({
            value: i,
            label: item.Model + "-" + item.Type,
          });
        });

        setModels(tempArr);

        localStorage.setItem("Radius", radius.value);
        localStorage.setItem("fmodel", selectedModel.label);
        localStorage.setItem(
          "CustomerName",
          selectedCustomer != null ? selectedCustomer.label : ""
        );
        localStorage.setItem(
          "customer_id",
          selectedCustomer != null ? selectedCustomer.value : 0
        );
        localStorage.setItem(
          "machines",
          JSON.stringify(response.ServiceValueResponse.Return)
        );

        setSelectedModel({ label: "Select model", value: null });
      }
      setMachineLoading(false);
      setLoading(false);
    } catch (e) {
      setErrors(e);
    }
  };
  const getCustomers = async () => {
    const tempArr = [];
    setCutomerLoading(true);
    setModels([
      {
        value: "",
        label: "",
      },
    ]);
    try {
      const response = await FilterService.customerList(searchInput);
      if (response.ServiceValueResponse.Return.Customers.length !== 0) {
        response.ServiceValueResponse.Return.Customers.map((item, i) => {
          tempArr.push({
            value: item.sCustomerId,
            label: item.sUserName,
          });
          setCustomers(tempArr);
        });
      } else {
        setCustomers([{ value: 0, label: "No Customer Found" }]);
      }
      setCutomerLoading(false);
    } catch (e) {
      setErrors(e);
    }
  };

  const applyFilter = async (cusID) => {
    var model = null;
    if (selectedModel.value != null) {
      model = selectedModel.label;
    }
    try {
      setLoading(true);
      const response = await FilterService.filter(
        cusID,
        model,
        kal,
        radius.value,
        lat,
        lng,
        serialNum,
        unitNum
      );
      if (response.ServiceValueResponse.Succeeded === true) {
        localStorage.setItem(
          "machinesData",
          JSON.stringify(response.ServiceValueResponse.Return)
        );
        setLoading(false);
        navigate("/results", {
          state: response.ServiceValueResponse.Return,
        });
      } else if (response.ServiceValueResponse.Code === 204) {
        alert(response.ServiceValueResponse.DisplayMessage);
        setLoading(false);
      }
    } catch (e) {
      if (e.response.status == 400) {
        setErrors(e.response.data.errors);
        setLoading(false);
      } else {
        setLoading(false);
        alert(e);
      }
    }
  };

  // const handleChange = (item) => {
  //   if (item !== null) {
  //     if (selectedCustomer.value !== 0) {
  //       setSelectedCustomer({
  //         value: 0,
  //         label: "",
  //       });
  //     }
  //     setSelectedCustomer({ value: item.value, label: item.label });
  //   }
  // };
  // const handleModelChange = (item) => {
  //   if (item !== null) {
  //     if (selectedModel.value !== null) {
  //       setSelectedModel({
  //         value: null,
  //         label: null,
  //       });
  //     }
  //     setSelectedModel({ value: item.value, label: item.label });
  //   }
  // };

  const handelSerial = (e) => {
    setSerialNum(e.target.value);
  };
  const handelUnit = (e) => {
    setUnitNum(e.target.value);
  };

  const clearFilter = () => {
    setCustomers([]);
    setSelectedCustomer({
      value: 0,
      label: "Type 3 characters",
    });
    setSelectedModel({
      value: null,
      label: null,
    });
    setRadius({ label: "All", value: 0 });
    setModels([]);
    setSerialNum("");
    setUnitNum("");
    if (customerID === "0") {
      getModelForRadius(selectedCustomer.value);
    } else {
      getModelForRadius(customerID);
    }
  };

  useEffect(() => {
    if (searchInput.length >= 3) {
      getCustomers();
    }
  }, [searchInput]);

  useEffect(() => {
    if (customerID === "0") {
      if (selectedCustomer != null) {
        getModelForRadius(selectedCustomer.value);
      } else {
        getModelForRadius(0);
      }
    } else {
      getModelForRadius(customerID);
    }
    setSelectedModel({ label: "Select model", value: null });
  }, [selectedCustomer, radius]);

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      setLoading(false);
      alert(errors);
    }
  }, [errors]);

  return (
    <>
      {/* <div className="App"> */}
      {/* <div className="container-fluid">
          <div className="row ">
            <div className="col"></div>
            <div className="col d-flex justify-content-end">
              <button
                className="filter-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Filter
              </button>
            </div>
          </div>
          <div
            className="open-filter collapse bg-white mx-3"
            id="collapseExample"
          >
            <div className="row d-flex justify-content-between">
              <div className="col-sm-6">
                <div className="heading-bar">Filter Machines in Radius</div>
                <div className="filter-input">
                  <label className=" label mb-1">Select Radius</label>
                  <select className="form-select">
                    <option>All</option>
                    <option>5 km</option>
                    <option>10 km</option>
                    <option>15 km</option>
                    <option>20 km</option>
                    <option>40 km</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="heading-bar">Filter Customer</div>
                <div className="filter-input">
                  <label className=" label mb-1">Customer ID</label>
                  <Select
                    isClearable
                    options={options}
                    value={selectedCutomer}
                    isLoading={loading}
                    onChange={setSelectedCutomer}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="heading-bar">Filter Machines</div>
              </div>
              <div className="col-sm-4">
                <div className="filter-input">
                  <label className=" label mb-1">Model-Type</label>
                  <select className="form-select ">
                    <option>All</option>
                    <option>KAL</option>
                    <option>KAL NZ</option>
                    <option>KAL NZ Rental</option>
                    <option>KAL 2</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className=" label mb-1" htmlFor="exampleInput">
                    U/N
                  </label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className=" label mb-1" htmlFor="exampleInput">
                    S/N
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInput"
                  />
                </div>
              </div>
              <div className="col-sm-12 text-center">
                <div className="filter-buttons my-3">
                  <button className="fltr-btn m-2">Clear</button>
                  <button
                    className="fltr-btn m-2"
                    onClick={() => navigate("/results")}
                  >
                    Apply
                  </button>
                  <button className="fltr-btn m-2">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row">
            <div className="col-md-6 offset-md-3 main-content p-0">
              <div
                className="row filter-header m-0 d-flex justify-content-center pt-2"
                style={{ height: "45px" }}
              >
                Filter
              </div>
              <div className="heading-bar">Filter machines near me</div>
              <div className="filter-input m-3 bg-white">
                <label className="bg-white label mb-1">Select radius</label>
                {/* <select
                  className="form-select bg-white"
                  onChange={(e) => setRadius(e.target.value)}
                >
                  {radiusOptions.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select> */}

                <Select
                  isSearchable={false}
                  value={radius}
                  onChange={setRadius}
                  options={radiusOptions}
                  placeholder="Select radius"
                />
              </div>
              {customerID === "0" ? (
                <>
                  <div className="heading-bar">Filter customer</div>
                  <div className="filter-input m-3 bg-white">
                    <label className="bg-white label mb-1">Customer</label>

                    <Select
                      isClearable
                      isSearchable
                      placeholder={"Type 3 characters"}
                      value={selectedCustomer}
                      isLoading={cutomerLoading}
                      onChange={setSelectedCustomer}
                      onInputChange={setSearchInput}
                      options={customers}
                      name="cus_id"
                    />
                    <input type="hidden" name="cus_id" />
                    {errors.hasOwnProperty("cus_id")
                      ? errors.cus_id.map((e, i) => (
                          <div id="i" className="text-danger mt-2 ml-1">
                            {e}
                          </div>
                        ))
                      : ""}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="heading-bar">Filter machines</div>
              <div className="filter-input m-3 bg-white">
                <label className="bg-white label mb-1">Model</label>
                <Select
                  // isClearable
                  type="text"
                  isSearchable
                  isDisabled={machineloading}
                  isLoading={machineloading}
                  value={selectedModel}
                  onChange={setSelectedModel}
                  options={models}
                  placeholder={"Select model"}
                />
              </div>
              <div className="row m-1">
                <div className="col">
                  <div className="form-group my-3 bg-white">
                    <label
                      className="bg-white label mb-1"
                      htmlFor="exampleInput"
                    >
                      Unit
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={unitNum}
                      onChange={handelUnit}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group my-3 bg-white">
                    <label
                      className="bg-white label mb-1"
                      htmlFor="exampleInput"
                    >
                      Serial
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInput"
                      value={serialNum}
                      onChange={handelSerial}
                    />
                  </div>
                </div>
              </div>
              <div className="filter-buttons bg-white my-3">
                <button className="fltr-btn m-2" onClick={clearFilter}>
                  Clear
                </button>
                <button
                  className="fltr-btn m-2"
                  onClick={
                    customerID === "0"
                      ? () =>
                          applyFilter(
                            selectedCustomer != null
                              ? selectedCustomer.value
                              : 0
                          )
                      : () => applyFilter(customerID)
                  }
                >
                  Apply
                </button>
                <button className="fltr-btn m-2" onClick={() => navigate(-1)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/*filter-collapse-content */}
    </>
  );
};

export default MainFilter;
