import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { IconContext } from "react-icons";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import MapContainer from "../../Components/Map";
import moment from "moment";
import { DateRangePicker, DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ServiceContactModal from "./Modal/ServiceContactModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import useWindowDimensions from "../../GetDimenssions/GetWindowSize";
import {
  inputDateFormat,
  outputDateRangeFormat,
  outputDateWithoutRangeFormat,
} from "../../Constants/global";
import formatDate from "../../DateTimeFormat/DateTimeFormatter";
const { allowedRange } = DateRangePicker;
let rowId = null;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const initialState = {
  contactForm: {
    UserId: null,
    MachineId: null,
    Desc: "",
    Date: "",
    SMR: null,
    Code: "",
    Frequency: "",
    ModelType: "",
    CustomerDesc: "",
    Module: "Caution",
    LatLng: -43.56489908 / 172.51389974,
    UserName: localStorage.getItem("User_name")
      ? localStorage.getItem("User_name")
      : "",
    EmailId: localStorage.getItem("Email") ? localStorage.getItem("Email") : "",
    Tel: localStorage.getItem("Tel") ? localStorage.getItem("Tel") : "",
    SerialNo: "",
  },
};
const Cautions = () => {
  let upperContentHeight;
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const ref = useRef(null);

  const [MachineData, setMachineData] = useState({});
  const [CautionGraph, setCautionGraph] = useState([]);
  const [cautions, setCautions] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [UNShow, setUNShow] = useState(false);
  const [MData, setMData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [ctnContactForm, setCtnContactForm] = useState(
    initialState.contactForm
  );

  const [goDirection, setGoDirection] = useState(false);
  const [selected, setSelected] = useState({});
  const [mapHeight, setMapHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(width);

  var CautionList = [];
  var MachineId;
  var tempArr = [];
  var LatestDate = state
    ? state.LatestDate
    : localStorage.getItem("machinesData").LatestDate;
  var EndDate = Endate().toString();

  const [eDate, setEDate] = useState(
    new Date(formatDate(EndDate, inputDateFormat, outputDateRangeFormat))
  );
  const [lDate, setLDate] = useState(
    new Date(formatDate(LatestDate, inputDateFormat, outputDateRangeFormat))
  );

  const handleCellClick = (status, id, SrNum) => {
    if (status === "UN") {
      setUNShow(true);
      DrawMap("Detailed", "cautions", id, SrNum, "");
    } else {
      setUNShow(false);
    }
  };
  const handleSingleClick = (id, SrNum) => {
    if (UNShow) {
      DrawMap("Detailed", "cautions", id, SrNum, "");
    }
    if (rowId !== id) {
      rowId = id;
      CautionGraphArray(id);
      contactOperator(id);
    }
    const alreadySelected = document.querySelector(".selected-row");
    if (alreadySelected) {
      alreadySelected.classList.remove("selected-row");
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    } else {
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    }
  };

  function Endate() {
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    var days_in_month = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var LatestDate = MachineData.LatestDate;

    //Split lates date into year , month and day
    var Latest_day = LatestDate.substring(6, 8);
    var Latest_month = parseInt(LatestDate.substring(4, 6)) - 1;
    var Latest_year = LatestDate.substring(0, 4);

    //declare new date Object
    var Dateobj = new Date(Latest_year, Latest_month, Latest_day);
    Dateobj.setDate(Dateobj.getDate() - 30);

    //get 30 days old(previous_values) from date object
    var Previous_Year = Dateobj.getFullYear();
    var Previous_Month = (Dateobj.getMonth() + 1).toString();
    var Previous_Day = Dateobj.getDate().toString();

    if (Previous_Month.length == 1) {
      Previous_Month = "0" + Previous_Month;
    }
    if (Previous_Day.length == 1) {
      Previous_Day = "0" + Previous_Day;
    }
    var Previous_Date = parseInt(Previous_Year + Previous_Month + Previous_Day);

    return Previous_Date;
  }

  function DrawMap(Screen, Row, MachineID, SerialNumber, Machine_data) {
    var Data = [];
    var markersArray = [];

    setLoading(true);
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    if (localStorage.getItem("Desired_Location") !== null) {
      //Delete previous deatination location from local storage
      localStorage.removeItem("Desired_Location");
    }

    if (Screen == "Detailed") {
      var Machines = MachineData.Machines;
      Machines.forEach((item) => {
        if (item.SerialNumber === SerialNumber) {
          if (Row == "cautions") {
            Data.length = 0;
            if (item.GPSDate !== null) {
              Data.push({
                Model: item.Model + "-" + item.Type,
                SerialNumber: item.SerialNumber,
                Lat: item.Location.Lat,
                Long: item.Location.Long,
                CustomerName: item.CustomerDesc,
                SMR: item.SMR,
                LastCommDate: item.LatestComDate,
                GPSDate: item.GPSDate,
                Icon: "/assets/img/pin_yellow.png",
              });
            }
          }
        }
      });
    }
    markersArray = Data;
    setMData(markersArray);
    setLoading(false);
  }

  function JsonCaution(NewDate, OldDate) {
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    CautionList = MachineData.CautionList; //Get th caution list
    var MachineExist = [];
    var Data = [];
    var LatestDate = MachineData.LatestDate;
    var Model, Type, SerialNumber;
    var i,
      m,
      c,
      j = 2,
      DataRow = 2,
      k = 1;

    for (i = 0; i < CautionList.length; i++) {
      //iterate through whole catuion list

      if (
        NewDate >=
          CautionList[i].Date.slice(0, CautionList[i].Date.length - 6) &&
        OldDate <= CautionList[i].Date.slice(0, CautionList[i].Date.length - 6)
      ) {
        //var Date = LatestDate - CautionList[i].Date.slice(0, CautionList[i].Date.length - 6); //used to track if cautions occur in a day, a week or a month

        var yy1 = LatestDate.substring(0, 4);
        var mm1 = LatestDate.substring(4, 6);
        var dd1 = LatestDate.substring(6, 8);

        var yy2 = CautionList[i].Date.substring(0, 4);
        var mm2 = CautionList[i].Date.substring(4, 6);
        var dd2 = CautionList[i].Date.substring(6, 8);

        var dt1 = new window.Date(yy1 + "-" + mm1 + "-" + dd1);
        var dt2 = new window.Date(yy2 + "-" + mm2 + "-" + dd2);

        var Date = Math.ceil((dt1 - dt2) / (1000 * 60 * 60 * 24));

        for (m = 0; m < CautionList[i].Machines.length; m++) {
          //Number of machines in a specific date
          var Day = 0,
            Week = 0,
            Month = 0;
          MachineId = CautionList[i].Machines[m].MachineID; // store machine id of each machine for comaprision
          for (var x = 0; x < MachineData.Machines.length; x++) {
            //Get machine info from machines
            if (MachineData.Machines[x].MachineId == MachineId) {
              var found = false;

              for (
                let a = 0;
                a < CautionList[i].Machines[m].Cautions.length;
                a++
              ) {
                //cautions in specific machine in a specific date
                if (Date <= 0) {
                  Day++;
                  Week++;
                  Month++;
                } else if (Date > 0 && Date < 8) {
                  Week++;
                  Month++;
                } else if (Date >= 8) {
                  Month++;
                }

                if (Data.length > 0) {
                  //if there is no data in Data Array no need to check if Machine already exist.
                  Data.forEach((item) => {
                    //loop through Data Array
                    if (item.MachineID === MachineId) {
                      found = true;
                      if (Date <= 0) {
                        // increment to existisng day counts
                        item.Days = item.Days + 1;
                        item.Weeks = item.Weeks + 1;
                        item.Months = item.Months + 1;
                      } else if (Date > 0 && Date < 8) {
                        var NewWeek = item.Weeks + 1;
                        item.Weeks = NewWeek;
                        item.Months = item.Months + 1;
                      } else if (Date >= 8) {
                        var NewMonth = item.Months + 1;
                        item.Months = NewMonth;
                      }
                    }
                  });
                  if (found == false) {
                    //Check if machines repeats or not

                    Data.push({
                      Row: "Row" + DataRow,
                      Model: MachineData.Machines[x].Model,
                      Type: MachineData.Machines[x].Type,
                      SerialNumber: MachineData.Machines[x].SerialNumber,
                      UnitNumber: MachineData.Machines[x].UnitNumber,
                      CustomerDesc: MachineData.Machines[x].CustomerDesc,
                      CustomerId: MachineData.Machines[x].CustomerId,
                      LatLong:
                        MachineData.Machines[x].Location.Lat +
                        "/" +
                        MachineData.Machines[x].Location.Long,
                      MachineID: MachineId,
                      Days: Day,
                      Weeks: Week,
                      Months: Month,
                    });
                    DataRow++;
                  }
                } else if (Data.length == 0) {
                  // If array is empty i.e Start of caution.html

                  Data.push({
                    Row: "Row" + DataRow,
                    Model: MachineData.Machines[x].Model,
                    Type: MachineData.Machines[x].Type,
                    SerialNumber: MachineData.Machines[x].SerialNumber,
                    UnitNumber: MachineData.Machines[x].UnitNumber,
                    CustomerDesc: MachineData.Machines[x].CustomerDesc,
                    CustomerId: MachineData.Machines[x].CustomerId,
                    LatLong:
                      MachineData.Machines[x].Location.Lat +
                      "/" +
                      MachineData.Machines[x].Location.Long,
                    MachineID: MachineId,
                    Days: Day,
                    Weeks: Week,
                    Months: Month,
                  });
                  DataRow++;
                }
              }
            }
          }
        }
      }
      setCopyData(Data);
    }
  }
  function CautionGraphArray(id) {
    CautionList = MachineData.CautionList;
    for (let i = 0; i < CautionList.length; i++) {
      //iterate through whole catuion list
      var Day = 0,
        Week = 0,
        Month = 0;
      if (
        formatRange(moment(lDate).format("MM/DD/YYYY")) >=
          CautionList[i].Date.slice(0, CautionList[i].Date.length - 6) &&
        formatRange(moment(eDate).format("MM/DD/YYYY")) <=
          CautionList[i].Date.slice(0, CautionList[i].Date.length - 6)
      ) {
        var Date = LatestDate - CautionList[i].Date; //used to track if cautions occur in a day, a week or a month
        for (var m = 0; m < CautionList[i].Machines.length; m++) {
          //Number of machines in a specific date, get machine id
          if (id == CautionList[i].Machines[m].MachineID) {
            for (
              var a = 0;
              a < CautionList[i].Machines[m].Cautions.length;
              a++
            ) {
              tempArr.push({
                Date: formatDte(CautionList[i].Date),
                Code: CautionList[i].Machines[m].Cautions[a].Code,
                Name: getCautionName(
                  CautionList[i].Machines[m].Cautions[a].Code
                ),
                Path: getCautionPath(
                  CautionList[i].Machines[m].Cautions[a].Code
                ),
                Map: CautionList[i].Machines[m].Cautions[a].Map,
              });
            }
          }
        }
      }
    }
    setCautionGraph(tempArr);
  }

  const getCautionName = (code) => {
    for (let i = 0; i < cautions.length; i++) {
      if (cautions[i].Code === code) {
        return cautions[i].Name;
      }
    }
  };
  const getCautionPath = (code) => {
    for (let i = 0; i < cautions.length; i++) {
      if (cautions[i].Code === code) {
        return cautions[i].Image;
      }
    }
  };

  const drawMap = (map) => {
    let tempArr = [];
    for (let i = 0; i < map.length; i++) {
      if (map[i] === "1") {
        tempArr.push(<div className="map-block-red" key={i}></div>);
      } else {
        tempArr.push(<div className="map-block-black" key={i}></div>);
      }
    }
    return tempArr;
  };

  function formatDate(date, range) {
    var dd = date.slice(6, 8);
    var mm = date.slice(4, 6); //January is 0!
    var yyyy = date.slice(0, 4);
    if (range) {
      var dateRange = yyyy + "-" + mm + "-" + dd;
      return dateRange;
    } else {
      var dte = yyyy + "/" + mm + "/" + dd;
      return dte;
    }
  }
  function formatDte(date) {
    var dd = date.slice(6, 8);
    var mm = date.slice(4, 6); //January is 0!
    var yyyy = date.slice(0, 4);
    var dt = dd + "/" + mm + "/" + yyyy;
    return dt;
  }
  function formatRange(date) {
    var mm = date.slice(0, 2);
    var dd = date.slice(3, 5);
    var yyyy = date.slice(6, 10);

    var dte = yyyy + mm + dd;
    return dte;
  }

  const contactOperator = (id) => {
    MachineData.Machines.map((item) => {
      if (item.MachineId === id) {
        setCtnContactForm({
          ...ctnContactForm,
          ["UserId"]: item.CustomerId,
          ["MachineId"]: item.MachineId,
          ["SMR"]: item.SMR,
          ["ModelType"]: item.Model + "-" + item.Type,
          ["CustomerDesc"]: item.CustomerDesc,
          ["LatLng"]: item.Location.Lat + "/" + item.Location.Long,
          ["SerialNo"]: item.SerialNumber,
          ["Desc"]: "",
          ["Date"]: "",
          ["Code"]: "",
        });
      }
    });
  };

  const getCautionData = (desc, date, code) => {
    setCtnContactForm({
      ...ctnContactForm,
      ["Desc"]: desc,
      ["Date"]: date,
      ["Code"]: code,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("machinesData")) {
      setMachineData(JSON.parse(localStorage.getItem("machinesData")));
      setCautions(JSON.parse(localStorage.getItem("Cautions")));
    } else {
      setMachineData(state);
      setCautions(JSON.parse(localStorage.getItem("Cautions")));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(MachineData).length !== 0) {
      JsonCaution(
        formatRange(moment(lDate).format("MM/DD/YYYY")),
        formatRange(moment(eDate).format("MM/DD/YYYY"))
      );
    }
  }, [MachineData]);

  useEffect(() => {
    if (copyData.length !== 0) {
      const row = document.getElementById(copyData[0].MachineID);
      row.classList.add("selected-row");
      rowId = copyData[0].MachineID;
      CautionGraphArray(copyData[0].MachineID);
      contactOperator(copyData[0].MachineID);
    }
  }, [copyData]);

  useEffect(() => {
    if (copyData.length !== 0) {
      CautionGraphArray(rowId);
    }
  }, [lDate, eDate]);

  useEffect(() => {
    if (goDirection) {
      navigate("/driving-direction");
    }
  }, [goDirection]);

  useLayoutEffect(() => {
    upperContentHeight = ref.current.clientHeight;
    setContentWidth(ref.current.clientWidth);
    setMapHeight(height - upperContentHeight);
  }, [height, copyData, width]);

  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{
                position: "relative",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            >
              <div ref={ref}>
                <div className="row filter-header m-0 d-flex justify-content-between align-items-center">
                  <div className="col">
                    <button className="header-btn" onClick={() => navigate(-1)}>
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <RiArrowGoBackLine /> {width <= 335 ? "" : "Back"}
                      </IconContext.Provider>
                    </button>
                  </div>
                  <div
                    className="col text-center"
                    style={{ paddingTop: "1px" }}
                  >
                    <img src="/assets/img/logo.png" alt="komtrax logo" />
                  </div>
                  <div className="col d-flex justify-content-end">
                    <button
                      className="header-btn"
                      onClick={() => navigate("/prefrence")}
                    >
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <FiSettings />
                        {width <= 335 ? "" : " Prefs"}
                      </IconContext.Provider>
                    </button>
                    {/* &nbsp;
                  <button
                    className="header-btn"
                    onClick={() => navigate("/filter")}
                  >
                    <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <BsSearch /> Filter
                    </IconContext.Provider>
                  </button> */}
                  </div>
                </div>
                <div className="row m-0">
                  <div
                    className="col p-0"
                    style={{ maxHeight: "27vh", overflowY: "auto" }}
                  >
                    <Table>
                      <thead className="fltr-table-head">
                        <tr className="text-center">
                          <th>Model</th>
                          <th>
                            {localStorage.getItem("ShowUN") === "true"
                              ? "Unit"
                              : "Serial"}
                          </th>
                          <th>1D</th>
                          <th>1W</th>
                          <th>1M</th>
                        </tr>
                      </thead>
                      {copyData.length !== 0 ? (
                        <tbody className="fltr-table-body">
                          {copyData.map((item, i) => (
                            <tr
                              key={i}
                              className="text-center"
                              onClick={() =>
                                handleSingleClick(
                                  item.MachineID,
                                  item.SerialNumber
                                )
                              }
                              id={item.MachineID}
                            >
                              <td
                                onClick={() =>
                                  handleCellClick("model", item.MachineID)
                                }
                              >
                                {item.Model + "-" + item.Type}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick(
                                    "UN",
                                    item.MachineID,
                                    item.SerialNumber
                                  )
                                }
                                id={item.MachineID}
                              >
                                {localStorage.getItem("ShowUN") === "true"
                                  ? item.UnitNumber
                                  : item.SerialNumber}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick("1D", item.MachineID)
                                }
                              >
                                {item.Days}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick("1W", item.MachineID)
                                }
                              >
                                {item.Weeks}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick("1M", item.MachineID)
                                }
                              >
                                {item.Months}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody></tbody>
                      )}
                    </Table>
                    {copyData.length === 0 && (
                      <div className="text-center text-muted">N/A</div>
                    )}
                  </div>
                </div>
                <div className="heading-bar text-center">Cautions</div>
                <div className="row px-4">
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      From
                    </label>
                    <DatePicker
                      oneTap
                      value={eDate}
                      onChange={setEDate}
                      disabledDate={allowedRange(
                        formatDate(
                          EndDate,
                          inputDateFormat,
                          outputDateWithoutRangeFormat
                        ),
                        formatDate(
                          LatestDate,
                          inputDateFormat,
                          outputDateWithoutRangeFormat
                        )
                      )}
                      placeholder="From date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      To
                    </label>
                    <DatePicker
                      oneTap
                      value={lDate}
                      onChange={setLDate}
                      disabledDate={allowedRange(
                        formatDate(
                          EndDate,
                          inputDateFormat,
                          outputDateWithoutRangeFormat
                        ),
                        formatDate(
                          LatestDate,
                          inputDateFormat,
                          outputDateWithoutRangeFormat
                        )
                      )}
                      placeholder="To date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div style={{ height: "20px" }}></div>
              </div>
              {UNShow ? (
                <div className="row">
                  <div className="col-12">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <MapContainer
                        setGoDirection={setGoDirection}
                        setSelected={setSelected}
                        selected={selected}
                        MData={MData.length === 0 ? null : MData}
                        height={mapHeight}
                        width={"100%"}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="row m-0">
                  <div
                    className="col table-responive mb-2"
                    style={{
                      minWidth: contentWidth,
                      maxHeight: mapHeight - 5,
                      overflow: "auto",
                    }}
                  >
                    {copyData.length !== 0 && (
                      <Table>
                        <tbody>
                          {CautionGraph.length !== 0 &&
                            CautionGraph.map((item, i) => (
                              <tr
                                className="text-center abnormalities-table"
                                key={i}
                              >
                                <td style={{ width: "45px" }}>
                                  <img
                                    src={`/assets/img${item.Path}`}
                                    alt="Caution-icon"
                                    width="30px"
                                    height="30px"
                                  />
                                </td>

                                <td style={{ width: "10vw" }}>
                                  {item.Date}
                                  <br />
                                  <span>{item.Name}</span>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <div className="row">
                                    <div className="col-12 d-flex">
                                      {drawMap(item.Map)}
                                    </div>
                                  </div>
                                  <div className="row d-flex justify-content-between">
                                    <div className="col text-start ">00:00</div>
                                    <div className="col text-align-center ">
                                      12:00
                                    </div>
                                    <div className="col text-end ">24:00</div>
                                  </div>
                                </td>
                                {/* <td>
                                  <img
                                    src="/assets/img/mail.png"
                                    alt="contact-icon"
                                    width="30px"
                                    height="30px"
                                    style={{ cursor: "pointer" }}
                                    data-toggle="modal"
                                    data-target="#serviceContact"
                                    onClick={() =>
                                      getCautionData(
                                        item.Name,
                                        item.Date,
                                        item.Code
                                      )
                                    }
                                  />
                                  <br />
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <ServiceContactModal
        contactForm={ctnContactForm}
        setContactForm={setCtnContactForm}
        setLoading={setContactLoading}
      /> */}

      <Backdrop className={classes.backdrop} open={contactLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Cautions;
