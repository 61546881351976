import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import useWindowDimensions from "../GetDimenssions/GetWindowSize";

function DirectionMap({ direction, Data }) {
  let upperContentHeight;
  const { height, width } = useWindowDimensions();
  const ref = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [mode, setMode] = useState("DRIVING");

  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [data, setData] = useState(Data);
  const [mapHeight, setMapHeight] = useState(0);

  const [currentLocation, setCurrentLocation] = useState(
    JSON.parse(localStorage.getItem("Current_Location"))
  );

  const center = data.position;

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  async function calculateRoute() {
    if (currentLocation == null || data.position == null) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    try {
      if (mode === "DRIVING") {
        const results = await directionsService.route({
          origin: currentLocation.lat + "," + currentLocation.lng,
          destination: data.position.lat + "," + data.position.lng,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        });
        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      } else {
        const results = await directionsService.route({
          origin: currentLocation.lat + "," + currentLocation.lng,
          destination: data.position.lat + "," + data.position.lng,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.WALKING,
        });
        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      }
    } catch (e) {
      alert(e.message.slice(31));
    }
  }
  //   function clearRoute() {
  //     setDirectionsResponse(null);
  //     setDistance("");
  //     setDuration("");
  //     originRef.current.value = "";
  //     destiantionRef.current.value = "";
  //   }

  const onMarkerClick = (marker) => {
    setActiveMarker(marker);
    setSelectedPlace();
    setShowingInfoWindow(true);
  };
  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  // const onMapClicked = () => {
  //   if (this.state.showingInfoWindow)
  //     this.setState({
  //       activeMarker: null,
  //       showingInfoWindow: false,
  //     });
  // };

  function formatDate(date, time) {
    if (date !== null) {
      if (time) {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var hr = date.slice(9, 11);
        var minu = date.slice(11, 13);
        var dte = dd + "/" + mm + "/" + yyyy + " " + hr + ":" + minu + "Hrs";
        return dte;
      } else {
        var dd = date.slice(6, 8);
        var mm = date.slice(4, 6); //January is 0!

        var yyyy = date.slice(0, 4);
        var dte = dd + "/" + mm + "/" + yyyy;
        return dte;
      }
    }
  }

  const handleMode = (id) => {
    const alreadySelected = document.querySelector(".select-mode");
    if (alreadySelected) {
      alreadySelected.classList.remove("select-mode");
      const row = document.getElementById(id);
      row.classList.add("select-mode");
      setMode(id);
    } else {
      const row = document.getElementById(id);
      row.classList.add("select-mode");
      setMode(id);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      const row = document.getElementById("DRIVING");
      row.classList.add("select-mode");
      calculateRoute();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [mode]);

  useLayoutEffect(() => {
    if (isLoaded) {
      upperContentHeight = ref.current.clientHeight;
      setMapHeight(height - upperContentHeight - 45);
    }
  }, [height, isLoaded]);

  if (!isLoaded) {
    return "Loading...";
  }

  return (
    <>
      <div ref={ref}>
        <div
          style={{
            padding: "10px",
            borderRadius: "lg",
            margin: "4",
            bgColor: "white",
            shadow: "base",
            minWidth: "container.md",
            zIndex: "1",
          }}
        >
          <div className="row">
            <div className="col-12">
              <Autocomplete>
                <input
                  className="form-control m-1"
                  disabled={true}
                  type="text"
                  ref={originRef}
                  placeholder="FROM: Current Location"
                  // defaultValue={
                  //   "From:" + currentLocation.lat + "," + currentLocation.lng
                  // }
                />
              </Autocomplete>
            </div>
            <div className="col-12 m-1">
              <Autocomplete>
                <input
                  className="form-control"
                  disabled={true}
                  type="text"
                  ref={destiantionRef}
                  placeholder={`To: ${Data.info}`}
                  // defaultValue={
                  //   "To:" + Data.position.lat + "," + Data.position.lng
                  // }
                />
              </Autocomplete>
            </div>
            {/* <div>
            <button type="submit" onClick={calculateRoute}>
              Calculate Route
            </button>
            <button aria-label="center back" onClick={clearRoute}>
              <FaTimes />
            </button>
          </div> */}
          </div>
          <div className="row mb-3">
            <div className="col d-flex justify-content-start">
              <div
                className="direction-mode mx-2"
                id="DRIVING"
                onClick={() => handleMode("DRIVING")}
              >
                <img alt="Driving" src="/assets/img/car.png" />
              </div>
              <div
                className="direction-mode"
                id="WALKING"
                onClick={() => handleMode("WALKING")}
              >
                <img alt="Driving" src="/assets/img/walk.png" />
              </div>
            </div>
          </div>
          {/* <div className="hstack gap-4 justify-content-between">
          <div>Distance: {distance} </div>
          <br />
          <div>Duration: {duration} </div>
          <button
            aria-label="center back"
            onClick={() => {
              map.panTo(center);
              map.setZoom(15);
            }}
          >
            <FaLocationArrow />
          </button>
        </div> */}
        </div>
      </div>
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{
          width: "100%",
          height: mapHeight,
          position: "absolute",
          bottom: 0,
        }}
        mapTypeId="hybrid"
        onLoad={(map) => setMap(map)}
      >
        {Data != null ? (
          direction == true ? (
            <Marker
              position={{
                lat: data.position.lat,
                lng: data.position.lng,
              }}
              icon={data.Icon}
            />
          ) : (
            data.map((item, i) => (
              <Marker
                key={i}
                id={item.Model}
                name={`Model: ${item.Model} & S/N: ${item.SerialNumber}<br> 
                Name: ${item.CustomerName}<br>
                SMR: ${item.SMR} & Date: ${formatDate(
                  item.LastCommDate,
                  false
                )}<br>
                GPS Date: ${formatDate(item.GPSDate, true)}<br>`}
                onClick={onMarkerClick}
                position={{ lat: item.Lat, lng: item.Long }}
                icon={item.Icon}
              />
            ))
          )
        ) : (
          ""
        )}
        {/* <InfoWindow
          marker={activeMarker}
          onClose={onInfoWindowClose}
          visible={showingInfoWindow}
        >
          <>
            <div
              style={{
                width: "190px",
                height: "auto",
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedPlace.name,
                }}
              ></div>
              <div
                className="direction-link"
                style={
                  selectedPlace.name === "Current location"
                    ? {
                        color: "white",
                        textDecoration: "none",
                        display: "none",
                      }
                    : {
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }
                }
              >
                View Driving Direction
              </div>
            </div>
          </>
        </InfoWindow> */}
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </GoogleMap>
    </>
  );
}

export default DirectionMap;
