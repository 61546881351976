import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./style.scss";

const TermsOfCondition = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [header, setHeader] = useState(state.Header);
  const [subject, setSubject] = useState(state.Subject);

  const handleAgree = () => {
    localStorage.setItem("show-agreement", true);
    navigate("/filter");
  };
  const handleDisagree = () => {
    navigate("/");
  };

  useEffect(() => {
    setHeader(state.Header);
    setSubject(state.Subject);
  }, [state]);
  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row">
            <div
              className="col-md-6 offset-md-3 p-0 term-main"
              style={{ position: "relative" }}
            >
              <div
                className="row filter-header m-0 d-flex justify-content-center pt-2"
                style={{ height: "45px" }}
              >
                Policy
              </div>
              <div
                className="row"
                style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}
              >
                <div
                  className=" col-12 terms-content"
                  style={{ maxHeight: "86vh", overflowY: "auto" }}
                >
                  <h3 className="terms-heading">
                    {header === "" ? "" : header}
                  </h3>
                  <br />
                  <div
                    className="terms-subject"
                    dangerouslySetInnerHTML={{ __html: subject }}
                  />
                  {/* {subject === "" ? "" : subject} */}
                </div>
              </div>
              <div
                className="row m-0"
                style={{
                  position: "absolute",
                  bottom: "0",
                  width: "100%",
                }}
              >
                <div className="col-12 terms-btn-bar text-center m-0">
                  <button className="terms-btn my-2 mx-2" onClick={handleAgree}>
                    I agree
                  </button>

                  <button
                    className="terms-btn my-2 mx-2"
                    onClick={handleDisagree}
                  >
                    I disagree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfCondition;
