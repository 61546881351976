import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { IconContext } from "react-icons";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import ChartLine from "./Charts/LineChart";
import ChartBar from "./Charts/BarChart";
import MapContainer from "../../Components/Map";
import { DateRangePicker, DatePicker } from "rsuite";
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import useWindowDimensions from "../../GetDimenssions/GetWindowSize";
const { allowedRange } = DateRangePicker;

let rowId = null;
let cellStatus = "SMR";

const WorkingHours = () => {
  let upperContentHeight;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { height, width } = useWindowDimensions();
  const ref = useRef(null);

  const [MachineData, setMachineData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [UNShow, setUNShow] = useState(false);
  const [MData, setMData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [goDirection, setGoDirection] = useState(false);
  const [selected, setSelected] = useState({});
  const [mapHeight, setMapHeight] = useState(0);

  var LatestDate = state
    ? state.LatestDate
    : localStorage.getItem("machinesData").LatestDate;
  var EndDate = Endate().toString();
  var Unit = localStorage.getItem("Unit");
  var LineGraphdata = [];
  var getlineminmax = [];

  const [eDate, setEDate] = useState(formatDate(EndDate, true));
  const [lDate, setLDate] = useState(formatDate(LatestDate, true));

  // console.log(width, "width");
  // console.log(height, "height");

  const handelUN = () => {
    cellStatus = "UN";
    setUNShow(true);
  };

  const handleCellClick = (status, id, SrNum) => {
    rowId = id;
    // if (status === "UN") {
    //   // DrawMap("working-hours", "True", id, SrNum, "", setLoading, setMData);
    //   cellStatus = "UN";
    //   setUNShow(true);
    // } else
    if (status === "SMR") {
      cellStatus = "SMR";
      setUNShow(false);
      ChartData(rowId, formatRange(eDate), formatRange(lDate));
    } else if (status === "Hrs") {
      cellStatus = "Hours";
      ChartData(rowId, formatRange(eDate), formatRange(lDate));
      setUNShow(false);
    } else if (status === "Idle") {
      cellStatus = "Idle (%)";
      ChartData(rowId, formatRange(eDate), formatRange(lDate));
      setUNShow(false);
    } else if (status === "Fuel") {
      cellStatus = "Fuel";
      ChartData(rowId, formatRange(eDate), formatRange(lDate));
      setUNShow(false);
    } else if (status === "Fuel Per Hour") {
      cellStatus = "Fuel Per Hour";
      ChartData(rowId, formatRange(eDate), formatRange(lDate));
      setUNShow(false);
    } else {
      setUNShow(false);
    }
  };
  const handleSingleClick = (id, SrNum) => {
    if (cellStatus === "UN" || UNShow) {
      DrawMap("working-hours", "True", id, SrNum, "");
    }
    if (rowId !== id) {
      rowId = id;
      ChartData(id, formatRange(eDate), formatRange(lDate));
    }
    const alreadySelected = document.querySelector(".selected-row");
    if (alreadySelected) {
      alreadySelected.classList.remove("selected-row");
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    } else {
      const row = document.getElementById(id);
      row.classList.add("selected-row");
    }
  };

  function Endate() {
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    // var days_in_month = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var LatestDate = MachineData.LatestDate;

    //Split lates date into year , month and day
    var Latest_day = LatestDate.substring(6, 8);
    var Latest_month = parseInt(LatestDate.substring(4, 6)) - 1;
    var Latest_year = LatestDate.substring(0, 4);

    //declare new date Object
    var Dateobj = new Date(Latest_year, Latest_month, Latest_day);
    Dateobj.setDate(Dateobj.getDate() - 30);

    //get 30 days old(previous_values) from date object
    var Previous_Year = Dateobj.getFullYear();
    var Previous_Month = (Dateobj.getMonth() + 1).toString();
    var Previous_Day = Dateobj.getDate().toString();
    if (Previous_Month.length === 1) {
      Previous_Month = "0" + Previous_Month;
    }
    if (Previous_Day.length === 1) {
      Previous_Day = "0" + Previous_Day;
    }
    var Previous_Date = parseInt(Previous_Year + Previous_Month + Previous_Day);
    return Previous_Date;
  }

  function DrawMap(Screen, Row, MachineID, SerialNumber, Machine_data) {
    var Data = [];
    var markersArray = [];

    setLoading(true);
    var MachineData = JSON.parse(localStorage.getItem("machinesData"));
    if (localStorage.getItem("Desired_Location") !== null) {
      //Delete previous deatination location from local storage
      localStorage.removeItem("Desired_Location");
    }

    if (Screen == "working-hours" && Row == "True") {
      Data.length = 0;
      for (var j = 0; j < MachineData.Machines.length; j++) {
        if (MachineData.Machines[j].SerialNumber == SerialNumber) {
          if (MachineData.Machines[j].GPSDate !== null) {
            Data.push({
              Model:
                MachineData.Machines[j].Model +
                "-" +
                MachineData.Machines[j].Type,
              SerialNumber: MachineData.Machines[j].SerialNumber,
              Lat: MachineData.Machines[j].Location.Lat,
              Long: MachineData.Machines[j].Location.Long,
              CustomerName: MachineData.Machines[j].CustomerDesc,
              SMR: MachineData.Machines[j].SMR,
              LastCommDate: MachineData.Machines[j].LatestComDate,
              GPSDate: MachineData.Machines[j].GPSDate,
              Icon: "/assets/img/pin_green.png",
            });
          }
        }
      }
      // ReloadMarkers();
    }
    markersArray = Data;
    setMData(markersArray);
    setLoading(false);
  }

  function WorkingHourGridValues(StartDate, Endate, i, Unit) {
    //Calcluate Hrs,idle% and fuel off each machine

    // var MachineId = MachineData.Machines[i].MachineId;
    var Return_Hour = 0;
    var Return_Idle = 0;
    var Return_Fuel = 0;
    var idle_hour = 0;
    var idle_actualhour = 0;
    var TotalFuel = 0;
    var Fuel_Per_Hour;
    for (var j = 0; j < MachineData.Days.length; j++) {
      // loop for Total Days
      if (
        StartDate >= MachineData.Days[j].Date &&
        Endate <= MachineData.Days[j].Date
      ) {
        for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
          //Loop For each Machine in Day
          if (
            MachineData.Machines[i].MachineId ===
            MachineData.Days[j].Machines[k].MachineId
          ) {
            //Condition for Matching ID's
            var ActualHour = MachineData.Days[j].Machines[k].ActualHours;

            var Hour = MachineData.Days[j].Machines[k].Hours;
            var Fuel = MachineData.Days[j].Machines[k].Fuel;

            if (Hour !== 0 || ActualHour !== 0) {
              Return_Hour += Hour / 60 / 60;
              idle_hour += Hour;
              idle_actualhour += ActualHour;
            }
            TotalFuel += Fuel;
          }
        }
      }
    }
    if (idle_actualhour !== 0 && idle_hour !== 0) {
      Return_Idle = ((idle_hour - idle_actualhour) / idle_hour) * 100;
    }

    if (Unit === "Metric") {
      Return_Fuel = TotalFuel / localStorage.getItem("FuelDissolution");
    } else {
      Return_Fuel =
        TotalFuel /
        localStorage.getItem("FuelDissolution") /
        localStorage.getItem("ConvL2G");
    }
    Fuel_Per_Hour = Return_Fuel / Return_Hour;
    if (isNaN(Fuel_Per_Hour) || !isFinite(Fuel_Per_Hour)) {
      Fuel_Per_Hour = null;
    }
    Return_Hour = Math.floor(Return_Hour * 10) / 10;
    Return_Idle = Math.floor(Return_Idle * 10) / 10;
    Return_Fuel = Math.floor(Return_Fuel * 10) / 10;
    Fuel_Per_Hour = Math.floor(Fuel_Per_Hour * 10) / 10;
    if (Return_Hour < 0 || isNaN(Return_Hour) || !isFinite(Return_Hour)) {
      Return_Hour = "-";
    }
    if (Return_Idle < 0 || isNaN(Return_Idle) || !isFinite(Return_Idle)) {
      Return_Idle = "-";
    }
    if (Return_Fuel < 0 || isNaN(Return_Fuel) || !isFinite(Return_Fuel)) {
      Return_Fuel = "-";
    }
    if (Fuel_Per_Hour < 0 || isNaN(Fuel_Per_Hour) || !isFinite(Fuel_Per_Hour)) {
      Fuel_Per_Hour = "-";
    }
    return [Return_Hour, Return_Idle, Return_Fuel, Fuel_Per_Hour];
  }

  const ChartData = (MId, startD, endD) => {
    var CurrentMachineSMR = 0;
    var tempArr = [];
    if (cellStatus === "SMR") {
      tempArr = [];
      for (var j = MachineData.Days.length - 1; j >= 0; j--) {
        CurrentMachineSMR = 0;
        if (
          endD >= MachineData.Days[j].Date &&
          startD <= MachineData.Days[j].Date
        ) {
          //Machines in a specific date
          var found = false;

          for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
            //Loop For each Machine in specfic Day
            if (MId == MachineData.Days[j].Machines[k].MachineId) {
              //Condition for Matching ID's

              var CurrentMachineSMR = MachineData.Days[j].Machines[k].SMR;
              CurrentMachineSMR = CurrentMachineSMR / 60;
              CurrentMachineSMR = Math.floor(CurrentMachineSMR * 10) / 10;
              if (CurrentMachineSMR >= 0) {
                // donot show if the value is zero or less then zero
                found = true;
                LineGraphdata.push(CurrentMachineSMR); // push value to array to display on graph
              }
            }
          }
          if (found == false) {
            LineGraphdata.push(null);
          }
          var tempDate = formatDate(MachineData.Days[j].Date);
          tempArr.push({
            date: tempDate,
            value: CurrentMachineSMR,
          });
        }
      }
    } else if (cellStatus === "Idle (%)") {
      tempArr = [];
      var Return_Idle = 0;
      var idle_hour = 0;
      var idle_actualhour = 0;
      for (j = MachineData.Days.length - 1; j >= 0; j--) {
        Return_Idle = 0;
        if (
          endD >= MachineData.Days[j].Date &&
          startD <= MachineData.Days[j].Date
        ) {
          var found = false;
          for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
            //Loop For each Machine in Day
            if (MId == MachineData.Days[j].Machines[k].MachineId) {
              //Condition for Matching ID's
              var ActualHour = MachineData.Days[j].Machines[k].ActualHours;
              var Hour = MachineData.Days[j].Machines[k].Hours;
              if (Hour != 0 || ActualHour != 0) {
                found = true;
                idle_hour = Hour;
                idle_actualhour = ActualHour;

                if (idle_hour != 0 && idle_actualhour != 0) {
                  Return_Idle =
                    ((idle_hour - idle_actualhour) / idle_hour) * 100;

                  if (!isNaN(Return_Idle) && Return_Idle > 0) {
                    // only show in graph if idle value is a valid number
                    LineGraphdata.push(Return_Idle);
                  } else {
                    LineGraphdata.push(null);
                  }
                } else {
                  LineGraphdata.push(null);
                }
              }
            }
          }
          if (found == false) {
            LineGraphdata.push(null);
          }
          tempDate = formatDate(MachineData.Days[j].Date);
          tempArr.push({
            date: tempDate,
            value: !isNaN(Return_Idle) && Return_Idle > 0 ? Return_Idle : 0,
          });
        }
      }
    } else if (cellStatus === "Hours") {
      tempArr = [];
      var Return_Hour = 0;
      for (var j = MachineData.Days.length - 1; j >= 0; j--) {
        // loop for Total Days
        var Found = false; //Check if Machine exist in this date
        if (
          endD >= MachineData.Days[j].Date &&
          startD <= MachineData.Days[j].Date
        ) {
          for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
            //Loop For each Machine in Day
            if (MId == MachineData.Days[j].Machines[k].MachineId) {
              //Condition for Matching ID's
              Found = true;
              var Hour = MachineData.Days[j].Machines[k].Hours;
              if (Hour != 0) {
                Return_Hour = Hour / 60 / 60;
              }
            }
          }
          tempDate = formatDate(MachineData.Days[j].Date);
          tempArr.push({
            date: tempDate,
            value: Return_Hour,
          });
        }
      }
    } else if (cellStatus === "Fuel") {
      tempArr = [];
      var Return_Fuel = 0;
      for (var j = MachineData.Days.length - 1; j >= 0; j--) {
        var Return_Fuel = 0;
        // loop for Total Days

        var Found = false; //Check if Machine exist in this date
        if (
          endD >= MachineData.Days[j].Date &&
          startD <= MachineData.Days[j].Date
        ) {
          for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
            //Loop For each Machine in Day
            if (MId == MachineData.Days[j].Machines[k].MachineId) {
              //Condition for Matching ID's
              Found = true;
              var Fuel = MachineData.Days[j].Machines[k].Fuel;
              if (Unit == "Metric") {
                Return_Fuel = Fuel / localStorage.getItem("FuelDissolution");
              } else {
                Return_Fuel =
                  Fuel /
                  localStorage.getItem("FuelDissolution") /
                  localStorage.getItem("ConvL2G");
              }

              Return_Fuel = Math.floor(Return_Fuel * 10) / 10;
            }
          }
          tempDate = formatDate(MachineData.Days[j].Date);
          tempArr.push({
            date: tempDate,
            value: Return_Fuel,
          });
        }
      }
    } else if (cellStatus == "Fuel Per Hour") {
      var Return_Fuel = 0;
      var Return_Hour = 0;
      var Fuel_Per_Hour = 0;
      for (var j = MachineData.Days.length - 1; j >= 0; j--) {
        // loop for Total Days

        var Found = false; //Check if Machine exist in this date
        if (
          endD >= MachineData.Days[j].Date &&
          startD <= MachineData.Days[j].Date
        ) {
          for (var k = 0; k < MachineData.Days[j].Machines.length; k++) {
            //Loop For each Machine in Day
            if (MId == MachineData.Days[j].Machines[k].MachineId) {
              //Condition for Matching ID's
              Found = true;
              var Hour = MachineData.Days[j].Machines[k].Hours;
              var Fuel = MachineData.Days[j].Machines[k].Fuel;
              if (Hour != 0) {
                Return_Hour = Hour / 60 / 60;
              }
              if (Unit == "Metric") {
                Return_Fuel = Fuel / localStorage.getItem("FuelDissolution");
              } else {
                Return_Fuel =
                  Fuel /
                  localStorage.getItem("FuelDissolution") /
                  localStorage.getItem("ConvL2G");
              }

              if (Return_Hour != 0) {
                Fuel_Per_Hour = Return_Fuel / Return_Hour;
                Fuel_Per_Hour = Math.floor(Fuel_Per_Hour * 10) / 10;
              }
            }
          }
          tempDate = formatDate(MachineData.Days[j].Date);
          tempArr.push({
            date: tempDate,
            value: Fuel_Per_Hour,
          });
        }
      }
    }
    setChartData(tempArr.reverse());
  };

  function formatDate(date, range) {
    var dd = date.slice(6, 8);
    var mm = date.slice(4, 6); //January is 0!
    var yyyy = date.slice(0, 4);
    if (range) {
      var dateRange = yyyy + "-" + mm + "-" + dd;
      return dateRange;
    } else {
      var dte = mm + "-" + dd + "-" + yyyy;
      return dte;
    }
  }
  function formatRange(date) {
    var d = moment(date);
    var dat = d.format("L");
    var mm = dat.slice(0, 2);
    var dd = dat.slice(3, 5);
    var yyyy = dat.slice(6, 10);

    var dte = yyyy + mm + dd;
    return dte;
  }

  useEffect(() => {
    if (localStorage.getItem("machinesData")) {
      setMachineData(JSON.parse(localStorage.getItem("machinesData")));
    } else {
      setMachineData(state);
    }
  }, [state]);

  useEffect(() => {
    if (Object.keys(MachineData).length !== 0) {
      if (MachineData.Machines.length !== 0) {
        const row = document.getElementById(MachineData.Machines[0].MachineId);
        row.classList.add("selected-row");
        rowId = MachineData.Machines[0].MachineId;
        cellStatus = "SMR";
        ChartData(
          MachineData.Machines[0].MachineId,
          formatRange(eDate),
          formatRange(lDate)
        );
      }
    }
  }, [MachineData]);

  useEffect(() => {
    if (rowId !== null) {
      if (eDate && lDate) {
        if (MachineData.Days) {
          ChartData(rowId, formatRange(eDate), formatRange(lDate));
        }
      }
    }
  }, [eDate, lDate]);

  useEffect(() => {
    if (goDirection) {
      navigate("/driving-direction");
    }
  }, [goDirection]);

  useLayoutEffect(() => {
    upperContentHeight = ref.current.clientHeight;
    setMapHeight(height - upperContentHeight);
  }, [height, MachineData]);

  moment.suppressDeprecationWarnings = true;

  return (
    <>
      <div className="App">
        <div className="container p-0" style={{ overflow: "hidden" }}>
          <div className="row px-2">
            <div
              className="col-md-6 offset-md-3 main-content p-0"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <div ref={ref}>
                <div className="row filter-header m-0 d-flex justify-content-between align-items-center">
                  <div className="col">
                    <button className="header-btn" onClick={() => navigate(-1)}>
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <RiArrowGoBackLine /> {width <= 335 ? "" : "Back"}
                      </IconContext.Provider>
                    </button>
                  </div>
                  <div
                    className="col text-center"
                    style={{ paddingTop: "1px" }}
                  >
                    <img src="/assets/img/logo.png" alt="komtrax logo" />
                  </div>
                  <div className="col d-flex justify-content-end">
                    <button
                      className="header-btn"
                      onClick={() => navigate("/prefrence")}
                    >
                      <IconContext.Provider
                        value={{ className: "header-btn-icons" }}
                      >
                        <FiSettings />
                        {width <= 335 ? "" : " Prefs"}
                      </IconContext.Provider>
                    </button>
                    {/* &nbsp;
                  <button
                    className="header-btn"
                    onClick={() => navigate("/filter")}
                  >
                    <IconContext.Provider
                      value={{ className: "header-btn-icons" }}
                    >
                      <BsSearch /> Filter
                    </IconContext.Provider>
                  </button> */}
                  </div>
                </div>
                <div className="row m-0">
                  <div
                    className="col p-0"
                    style={{ maxHeight: "27vh", overflowY: "auto" }}
                  >
                    <Table>
                      <thead className="fltr-table-head">
                        <tr className="text-center">
                          <th>Model</th>
                          <th>
                            {localStorage.getItem("ShowUN") === "true"
                              ? "Unit"
                              : "Serial"}
                          </th>
                          <th>SMR</th>
                          <th>Hours</th>
                          <th>Idle</th>
                          <th>
                            {localStorage.getItem("Fuel_Ltr_Hr") === "true"
                              ? "Fuel/Hr"
                              : "Fuel"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="fltr-table-body">
                        {Object.keys(MachineData).length !== 0 &&
                          MachineData.Machines.map((item, i) => (
                            <tr
                              key={i}
                              className="text-center"
                              onClick={() =>
                                handleSingleClick(
                                  item.MachineId,
                                  item.SerialNumber
                                )
                              }
                              id={item.MachineId}
                            >
                              <td>{item.Model + "-" + item.Type}</td>
                              <td
                                onClick={
                                  handelUN
                                  // handleCellClick(
                                  //   "UN",
                                  //   item.MachineId,
                                  //   item.SerialNumber
                                  // )
                                }
                                id="UN"
                              >
                                {localStorage.getItem("ShowUN") === "true"
                                  ? item.UnitNumber
                                  : item.SerialNumber}
                              </td>
                              <td
                                onClick={() =>
                                  handleCellClick("SMR", item.MachineId)
                                }
                                id="SMR"
                              >
                                {item.SMR}
                              </td>
                              <td
                                id="Hrs"
                                onClick={() =>
                                  handleCellClick("Hrs", item.MachineId)
                                }
                              >
                                {
                                  WorkingHourGridValues(
                                    LatestDate,
                                    EndDate,
                                    i,
                                    Unit
                                  )[0]
                                }
                              </td>
                              <td
                                id="Idle"
                                onClick={() =>
                                  handleCellClick("Idle", item.MachineId)
                                }
                              >
                                {WorkingHourGridValues(
                                  LatestDate,
                                  EndDate,
                                  i,
                                  Unit
                                )[1] + "%"}
                              </td>
                              <td
                                id="Fuel"
                                onClick={
                                  localStorage.getItem("Fuel_Ltr_Hr") === "true"
                                    ? () =>
                                        handleCellClick(
                                          "Fuel Per Hour",
                                          item.MachineId
                                        )
                                    : () =>
                                        handleCellClick("Fuel", item.MachineId)
                                }
                              >
                                {localStorage.getItem("Fuel_Ltr_Hr") === "true"
                                  ? WorkingHourGridValues(
                                      LatestDate,
                                      EndDate,
                                      i,
                                      Unit
                                    )[3]
                                  : WorkingHourGridValues(
                                      LatestDate,
                                      EndDate,
                                      i,
                                      Unit
                                    )[2]}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="heading-bar text-center">{cellStatus}</div>
                <div className="row px-4">
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      From
                    </label>
                    <DatePicker
                      oneTap
                      value={moment(eDate).toDate()}
                      onChange={setEDate}
                      disabledDate={allowedRange(
                        formatDate(EndDate),
                        formatDate(LatestDate)
                      )}
                      placeholder="From date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col d-flex flex-column">
                    <label className="bg-white label mb-1 text-center">
                      To
                    </label>
                    <DatePicker
                      oneTap
                      value={moment(lDate).toDate()}
                      onChange={setLDate}
                      disabledDate={allowedRange(
                        formatDate(EndDate),
                        formatDate(LatestDate)
                      )}
                      placeholder="To date"
                      cleanable={false}
                      placement="auto"
                      format="dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div
                  style={
                    (cellStatus === "UN" && UNShow) || width >= 992
                      ? { height: "10px" }
                      : { height: "30px" }
                  }
                ></div>
              </div>
              <div className="row">
                {cellStatus === "UN" && UNShow ? (
                  loading ? (
                    <div>Loading...</div>
                  ) : (
                    <MapContainer
                      setGoDirection={setGoDirection}
                      setSelected={setSelected}
                      selected={selected}
                      MData={MData.length === 0 ? null : MData}
                      height={mapHeight}
                      width={"100%"}
                    />
                  )
                ) : mapHeight != 0 ? (
                  cellStatus === "SMR" ? (
                    <ChartLine chartData={chartData} mapHeight={mapHeight} />
                  ) : (
                    <ChartBar chartData={chartData} mapHeight={mapHeight} />
                  )
                ) : (
                  "Loading..."
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingHours;
